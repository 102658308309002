import * as React from 'react';
import { Box, FormControlLabel, Switch } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { ClickEventValue } from 'google-map-react';
import { Spacer, TextField, Typography } from '../../../../../../../atoms';
import {
  AddressAutocomplete,
  AddressAutocompleteProps,
} from '../../../../../../../molecules';
import MapClickable from '../../../../../../../organisms/Map/MapClickable';

import {
  getCityFromPlace,
  getCountryFromPlace,
  getGeometryFromPlace,
  getPostcodeFromPlace,
} from '../../../../../../../../api/googleMaps/gcpPlaces/utils';
import { PlaceResult } from '../../../../../../../../api/googleMaps/gcpPlaces/types';

import { defaultLatLng } from '../../../../../../../../config/search/globals';
import { LatLngCoords } from '../../../../../../../../utils/geolocation';
import { formatNumber } from '../../../../../../../../utils/number';
import useIsScreenType from '../../../../../../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../../../../../../config';

import { FormTitle } from '../../../components';
import { BaseFormProps, LocationFieldValues } from '../types';
import useStyles from './LocationForm.styles';
import { inferCityAreaRegionFromPostcode } from '../../../../../../../../utils/property';

export type LocationFormProps = BaseFormProps<LocationFieldValues> & {
  postcode: string;
  setAreaRegion: (props: { area: string; region: string }) => void;
  setCity: (city: string) => void;
  setLocalAuthority: (localAuthority: string) => void;
  geometry: LatLngCoords | undefined;
  setGeometry: (geometry: LatLngCoords) => void;
  selectedPlaceDetails: PlaceResult | null;
  setAddressTexts: (props: {
    address: string;
    postcode: string;
    city: string;
    country: string;
  }) => void;
  mapRef: React.RefObject<unknown>;
  mapsRef: React.RefObject<unknown>;
  addressAutocompleteProps: AddressAutocompleteProps;
};

function formatCoord(coord: number): string {
  return formatNumber(coord, {
    decimalPlaces: 4,
  });
}

// TODO: re-enable this when we find a way to update nearby places at less costs
const GLOBAL_DISABLED = false;

export default function LocationForm({
  defaultValues,
  formControl,
  formSubmit,
  formSubmitIsLoading,
  postcode,
  setAreaRegion,
  setCity,
  geometry,
  setGeometry,
  selectedPlaceDetails,
  setAddressTexts,
  mapRef,
  mapsRef,
  addressAutocompleteProps,
}: LocationFormProps) {
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({ isMobile });

  // In free text mode, the autocomplete address field is disabled.
  const [freeTextMode, setFreeTextMode] = React.useState(false);

  const formattedGeometry = geometry
    ? { lat: formatCoord(geometry.lat), lng: formatCoord(geometry.lng) }
    : { lat: 'Unknown', lng: 'Unknown' };

  const handleMapClick = (e: ClickEventValue) => {
    if (!GLOBAL_DISABLED && freeTextMode) {
      setGeometry({
        lat: e.lat,
        lng: e.lng,
      });
    }
  };

  const selectedPlaceDetailsId = selectedPlaceDetails?.place_id;
  React.useEffect(() => {
    if (!freeTextMode && selectedPlaceDetailsId && selectedPlaceDetails) {
      const { location: selectedPlaceLocationGeometry } =
        getGeometryFromPlace(selectedPlaceDetails);

      setGeometry(selectedPlaceLocationGeometry);
      setAddressTexts({
        address: selectedPlaceDetails.formatted_address ?? '',
        postcode: getPostcodeFromPlace(selectedPlaceDetails),
        city: getCityFromPlace(selectedPlaceDetails),
        country: getCountryFromPlace(selectedPlaceDetails),
      });
    }
  }, [freeTextMode, selectedPlaceDetailsId]);

  React.useEffect(() => {
    const { city, area, region } = inferCityAreaRegionFromPostcode(postcode);
    setAreaRegion({ area, region });

    if (freeTextMode) {
      setCity(city);
    }
  }, [postcode]);

  return (
    <form onSubmit={formSubmit}>
      <Box className={classes.formCtn}>
        <FormTitle title="Location" />

        <Spacer y={2} />
        <Typography className={classes.note}>
          To change this property&#39;s location, please contact us at{' '}
          <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>
        </Typography>

        <Spacer y={3.25} />

        <Box className={classes.formFieldsCtn}>
          <Box className={classes.formFields}>
            <FormControlLabel
              control={
                <Switch
                  checked={freeTextMode}
                  onChange={() => setFreeTextMode((prev) => !prev)}
                  color="primary"
                  disabled={GLOBAL_DISABLED || formSubmitIsLoading}
                />
              }
              label="Enter address manually instead"
            />
            <AddressAutocomplete
              {...addressAutocompleteProps}
              disabled={GLOBAL_DISABLED || formSubmitIsLoading || freeTextMode}
            />

            <Controller<LocationFieldValues>
              name="address"
              control={formControl}
              defaultValue={defaultValues?.address ?? ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Address"
                  fullWidth
                  disabled={
                    GLOBAL_DISABLED || formSubmitIsLoading || !freeTextMode
                  }
                />
              )}
            />
            <Controller<LocationFieldValues>
              name="postcode"
              control={formControl}
              defaultValue={defaultValues?.postcode ?? ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Postcode"
                  fullWidth
                  disabled={
                    GLOBAL_DISABLED || formSubmitIsLoading || !freeTextMode
                  }
                />
              )}
            />
            <Controller<LocationFieldValues>
              name="city"
              control={formControl}
              defaultValue={defaultValues?.city ?? ''}
              render={({ field }) => (
                <TextField {...field} label="City" fullWidth disabled />
              )}
            />
            <Controller<LocationFieldValues>
              name="country"
              control={formControl}
              defaultValue={defaultValues?.country ?? ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Country"
                  fullWidth
                  disabled={
                    GLOBAL_DISABLED || formSubmitIsLoading || !freeTextMode
                  }
                />
              )}
            />
            <Controller<LocationFieldValues>
              name="area"
              control={formControl}
              defaultValue={defaultValues?.area ?? ''}
              render={({ field }) => (
                <TextField {...field} label="Area" fullWidth disabled />
              )}
            />
            <Controller<LocationFieldValues>
              name="region"
              control={formControl}
              defaultValue={defaultValues?.region ?? ''}
              render={({ field }) => (
                <TextField {...field} label="Region" fullWidth disabled />
              )}
            />
            <Controller<LocationFieldValues>
              name="local_authority"
              control={formControl}
              defaultValue={defaultValues?.local_authority ?? ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Local authority"
                  fullWidth
                  disabled={GLOBAL_DISABLED || formSubmitIsLoading}
                />
              )}
            />
          </Box>
          <Box className={classes.map}>
            <Typography>Latitude: {formattedGeometry.lat}</Typography>
            <Typography>Longitude: {formattedGeometry.lng}</Typography>
            {!GLOBAL_DISABLED && (
              <Typography>
                {freeTextMode
                  ? `Click on the map to change coordinates`
                  : `Select an address in the Address search to change coordinates`}
              </Typography>
            )}
            <Box height={isMobile ? 250 : '100%'}>
              <MapClickable
                handleClick={handleMapClick}
                mapConfig={{
                  center: geometry ?? defaultLatLng,
                  zoom: 16,
                  hoverDistance: 24,
                }}
                mapRef={mapRef}
                mapsRef={mapsRef}
                pinsData={
                  geometry ? [{ lat: geometry.lat, lng: geometry.lng }] : []
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
}
