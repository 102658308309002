import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { Select, Snackbar, SnackbarType } from '../../../../../../atoms';
import useIsScreenType from '../../../../../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../../../../../config';
import useDevelopment from '../../../../../../../api/developments/useDevelopment';
import useUpdateDevelopment from '../../../../../../../api/developments/useUpdateDevelopment';
import { FormSection, SnackbarContent } from '../../components';
import useStyles from './PublicListingPage.styles';
import { RouteComponentProps } from '@reach/router';
import useAuthToken from '../../../../../../../store/auth/hooks/useAuthToken';
import { useSnackbar } from '../../../../../../../hooks';
import { EditPropertyFormLayout } from '../../../../../../layout';
import { APP_ROUTES } from '../../../../../../../config/app';

interface PublicListingFormFieldValues {
  is_public: boolean | string;
}

export default function PublicListingPage({
  developmentId,
  orgId,
}: RouteComponentProps<{ developmentId: string; orgId: string }>) {
  const normalizedOrgId = orgId ? Number(orgId) : 0;
  const normalizedDevelopmentId = developmentId ? Number(developmentId) : 0;

  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({ isMobile });
  const authToken = useAuthToken();
  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const useDataCommonProps = {
    authToken,
    orgId: normalizedOrgId,
    developmentId: normalizedDevelopmentId,
  };

  const linkToEditPropertyRoot =
    APP_ROUTES.propertyManagementEditDevelopmentRoot({
      orgId: normalizedOrgId,
      developmentId: normalizedDevelopmentId,
    });

  const { data: development, status: developmentStatus } =
    useDevelopment(useDataCommonProps);
  const { mutate: updateDevelopment, status: updateDevelopmentStatus } =
    useUpdateDevelopment();

  const isPageDataLoading = developmentStatus === 'loading';

  const { control, handleSubmit } = useForm<PublicListingFormFieldValues>();

  const defaultValues: PublicListingFormFieldValues | undefined = development
    ? {
        is_public: development.data.is_public,
      }
    : undefined;

  const formSubmitHandlerOnSuccess = () => {
    displaySnackbar(
      SnackbarType.SUCCESS,
      <SnackbarContent text="Your information has been successfully saved" />
    );
  };
  const formSubmitHandlerOnError = (error) => {
    displaySnackbar(
      SnackbarType.ERROR,
      <SnackbarContent
        text={`Failed to update development. Reason: ${
          (error as Error).message
        }`}
      />
    );
  };

  const formSubmitHandler: SubmitHandler<PublicListingFormFieldValues> = (
    data
  ) => {
    updateDevelopment(
      {
        ...useDataCommonProps,
        updater: {
          is_public: data.is_public === 'true',
        },
      },
      {
        onSuccess: formSubmitHandlerOnSuccess,
        onError: formSubmitHandlerOnError,
      }
    );
  };

  const formSubmit = handleSubmit(formSubmitHandler);

  const formSubmitIsLoading = updateDevelopmentStatus === 'loading';

  return (
    <Box className={classes.pageCtn}>
      <EditPropertyFormLayout
        title="PUBLIC LISTING"
        mainPageUrl={linkToEditPropertyRoot}
        formIsLoading={isPageDataLoading}
        formSubmitHandler={formSubmit}
        formSubmitIsLoading={formSubmitIsLoading}
      >
        <FormSection formOnSubmit={formSubmit}>
          <Box className={classes.formControllersCtn}>
            <Controller<PublicListingFormFieldValues>
              name="is_public"
              control={control}
              defaultValue={defaultValues?.is_public ? 'true' : 'false'}
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth
                  label="Are you ready to public your listing?"
                  options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                  ]}
                  disabled={formSubmitIsLoading}
                />
              )}
            />
          </Box>
        </FormSection>
        <Snackbar
          hideSnackbar={hideSnackbar}
          show={snackbarState.show}
          type={snackbarState.type}
        >
          <>{snackbarState.content}</>
        </Snackbar>
      </EditPropertyFormLayout>
    </Box>
  );
}
