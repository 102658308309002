import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box } from '@material-ui/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EditPropertyFormLayout } from '../../../../../../layout';
import { Snackbar, SnackbarType } from '../../../../../../atoms';

import useDevelopment from '../../../../../../../api/developments/useDevelopment';
import useUpdateDevelopment from '../../../../../../../api/developments/useUpdateDevelopment';
import { useSnackbar } from '../../../../../../../hooks';
import useAuthToken from '../../../../../../../store/auth/hooks/useAuthToken';
import { APP_ROUTES } from '../../../../../../../config/app';

import { AttributesSelects, SnackbarContent } from '../../components';
import useStyles from './FacilitiesSubPage.styles';
import attributesInputData from './attributesInputData';
import { FacilitiesFieldValues } from './types';

export default function FacilitiesSubPage({
  orgId,
  developmentId,
}: RouteComponentProps<{ developmentId: string; orgId: string }>) {
  const normalizedDevelopmentId = Number(developmentId) ?? 0;
  const normalizedOrgId = Number(orgId) ?? 0;

  const classes = useStyles();
  const authToken = useAuthToken();
  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const {
    data: developmentResponse,
    isSuccess: developmentLoaded,
    isLoading: developmentLoading,
  } = useDevelopment({
    authToken,
    orgId: normalizedOrgId,
    developmentId: normalizedDevelopmentId,
  });

  const { mutate: updateDevelopment, isLoading: updatingDevelopmentStatus } =
    useUpdateDevelopment();

  const formSubmitHandlerOnSuccess = () => {
    displaySnackbar(
      SnackbarType.NORMAL,
      <SnackbarContent text="Your information has been successfully saved" />
    );
  };

  const formSubmitHandlerOnError = (error: Error) => {
    displaySnackbar(
      SnackbarType.ERROR,
      <SnackbarContent
        text={`Failed to update development. Reason: ${error.message}`}
      />
    );
  };

  const facilitiesFormSubmitHandler: SubmitHandler<FacilitiesFieldValues> = (
    formData
  ) => {
    Object.keys(formData).forEach((key) => {
      formData[key] =
        String(formData[key] ?? '').trim() === '' ? null : formData[key];
    });

    void updateDevelopment(
      {
        authToken,
        orgId: normalizedOrgId,
        developmentId: normalizedDevelopmentId,
        updater: formData,
      },
      {
        onSuccess: formSubmitHandlerOnSuccess,
        onError: formSubmitHandlerOnError,
      }
    );
  };

  const {
    control: facilitiesFormControl,
    handleSubmit: facilitiesFormSubmitFactory,
  } = useForm<FacilitiesFieldValues>();

  const facilitiesFormSubmit = facilitiesFormSubmitFactory(
    facilitiesFormSubmitHandler
  );

  const linkToEditPropertyRoot =
    APP_ROUTES.propertyManagementEditDevelopmentRoot({
      orgId: normalizedOrgId,
      developmentId: normalizedDevelopmentId,
    });

  return (
    <Box className={classes.pageCtn}>
      <EditPropertyFormLayout
        title="FACILITIES"
        mainPageUrl={linkToEditPropertyRoot}
        formIsLoading={developmentLoading}
        formSubmitHandler={facilitiesFormSubmit}
        formSubmitIsLoading={updatingDevelopmentStatus}
      >
        {developmentLoaded && (
          <AttributesSelects
            development={developmentResponse?.data}
            attributes={attributesInputData}
            attributeTitle="Facilities"
            attributeDescription="Please select the facilities you provide to all tenants"
            formControl={facilitiesFormControl}
            formSubmitHandler={facilitiesFormSubmit}
          />
        )}
      </EditPropertyFormLayout>
      <Snackbar
        hideSnackbar={hideSnackbar}
        show={snackbarState.show}
        type={snackbarState.type}
      >
        <>{snackbarState.content}</>
      </Snackbar>
    </Box>
  );
}
