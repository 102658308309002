import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box } from '@material-ui/core';
import { Image, VideoCall } from '@material-ui/icons';
import { Snackbar, SnackbarType } from '../../../../../../atoms';
import {
  InteractiveMediaVariant,
  MediaUploadArea,
} from '../../../../../../molecules';
import { EditPropertyFormLayout } from '../../../../../../layout';

import useAuthToken from '../../../../../../../store/auth/hooks/useAuthToken';
import useDevelopment from '../../../../../../../api/developments/useDevelopment';
import useDevelopmentVideos, {
  VideoData,
} from '../../../../../../../api/developments/useDevelopmentVideos';
import useDevelopmentImages from '../../../../../../../api/developments/useDevelopmentImages';
import { DevelopmentImage } from '../../../../../../../api/property/types';
import {
  ACCEPTABLE_DEVELOPMENT_IMAGE_MIME_TYPES,
  ACCEPTABLE_VIDEO_MIME_TYPES,
  MAX_DEVELOPMENT_IMAGE_FILE_SIZE,
  MAX_DEVELOPMENT_IMAGE_FILES_AT_ONCE,
  MAX_VIDEO_FILE_SIZE,
  MAX_VIDEO_FILES_AT_ONCE,
  useSnackbar,
  useUploadDevelopmentImages,
  useUploadVideos,
} from '../../../../../../../hooks';
import { APP_ROUTES } from '../../../../../../../config/app';
import useIsScreenType from '../../../../../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../../../../../config';

import useStyles from './DevelopmentMediasSubPage.styles';
import {
  DevelopmentImageInteractiveMedia,
  DevelopmentVideoInteractiveMedia,
  FormSection,
  MediaUploadAreaContainer,
  SnackbarContent,
} from '../../components';
import useUpdateDevelopmentImage from '../../../../../../../api/developments/useUpdateDevelopmentImage';
import { OrgFileStatus } from '../../../../../../../api/orgs/types';

// Since videos have post-upload processing, we can't do query invalidation post
// mutation after a video has been uploaded. The post-upload processing would
// have been still ongoing at that time.
// Therefore, we've opted to execute a regular refetch interval instead.
const VIDEOS_REFETCH_INTERVAL = 3000; // Refetch video data every 3s

export default function DevelopmentMediasSubPage({
  orgId,
  developmentId,
}: RouteComponentProps<{ orgId: string; developmentId: string }>) {
  const normalizedOrgId = Number(orgId) ?? 0;
  const normalizedDevelopmentId = Number(developmentId) ?? 0;

  // Not actually 'mobile' screen type because we need things to be a bit wider
  // for the 2-column input fields layout.
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);

  const classes = useStyles({ isMobile });

  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const authToken = useAuthToken();

  const commonApiProps = {
    authToken,
    orgId: normalizedOrgId,
    developmentId: normalizedDevelopmentId,
  };

  const { data: development, status: developmentStatus } =
    useDevelopment(commonApiProps);

  const primaryMediaId = development?.data.img_thumbnail_id;

  const { data: developmentVideos, status: developmentVideosStatus } =
    useDevelopmentVideos({
      ...commonApiProps,
      queryOpts: {
        refetchInterval: VIDEOS_REFETCH_INTERVAL,
      },
    });

  const { data: developmentImages, status: developmentImagesStatus } =
    useDevelopmentImages(commonApiProps);

  const developmentVideosData = developmentVideos?.data.reduce<{
    primary: VideoData | null;
    nonPrimaries: VideoData[];
  }>(
    (developmentVideosData, developmentVideo) => {
      if (
        primaryMediaId &&
        developmentVideo.video.orgimages_id === primaryMediaId
      ) {
        developmentVideosData.primary = developmentVideo;
      } else {
        developmentVideosData.nonPrimaries.push(developmentVideo);
      }

      return developmentVideosData;
    },
    { primary: null, nonPrimaries: [] }
  );

  const developmentImagesData = developmentImages?.data.reduce<{
    primary: DevelopmentImage | null;
    nonPrimaries: DevelopmentImage[];
  }>(
    (developmentImagesData, developmentImage) => {
      if (primaryMediaId && developmentImage.orgimages_id === primaryMediaId) {
        developmentImagesData.primary = developmentImage;
      } else {
        developmentImagesData.nonPrimaries.push(developmentImage);
      }

      return developmentImagesData;
    },
    { primary: null, nonPrimaries: [] }
  );

  const { stagingVideos, onNewVideos } = useUploadVideos({
    orgId: normalizedOrgId,
    developmentId: normalizedDevelopmentId,
    onNewVideosError: (message) => {
      displaySnackbar(SnackbarType.ERROR, <SnackbarContent text={message} />);
    },
    onVideosUploadStart: (data) => {
      displaySnackbar(
        SnackbarType.NORMAL,
        <SnackbarContent text={`Uploading your ${data.length} videos`} />
      );
    },
    onVideoUploadSuccess: ({ file }) => {
      displaySnackbar(
        SnackbarType.SUCCESS,
        <SnackbarContent text="Your videos will appear shortly" />
      );
    },
    onVideoUploadError: ({ file, message }) => {
      displaySnackbar(
        SnackbarType.ERROR,
        <SnackbarContent
          text={`Could not upload ${file.name}. Reason: ${message}`}
        />
      );
    },
  });

  const { stagingImages, onNewImages } = useUploadDevelopmentImages({
    orgId: normalizedOrgId,
    developmentId: normalizedDevelopmentId,
    onNewImagesError: (message) => {
      displaySnackbar(SnackbarType.ERROR, <SnackbarContent text={message} />);
    },
    onImageUploadSuccess: () => {
      displaySnackbar(
        SnackbarType.SUCCESS,
        <SnackbarContent text="Image uploaded successfully" />
      );
    },
    onImageUploadError: ({ file, message }) => {
      displaySnackbar(
        SnackbarType.ERROR,
        <SnackbarContent
          text={`Could not upload ${file.name}. Reason: ${message}`}
        />
      );
    },
  });

  const {
    mutate: updateDevelopmentImage,
    isSuccess: isUpdateDevelopmentImageSuccess,
    isLoading: isUpdateDevelopmentImageLoading,
  } = useUpdateDevelopmentImage({
    queryConfig: {
      onSuccess: () => {
        displaySnackbar(
          SnackbarType.SUCCESS,
          <SnackbarContent text="Image updated successfully" />
        );
      },
      onError: (error) => {
        displaySnackbar(
          SnackbarType.ERROR,
          <SnackbarContent
            text={`Failed to update image. Reason: ${error.message}`}
          />
        );
      },
    },
  });

  const interactiveMediaVariant = isMobile
    ? InteractiveMediaVariant.BEST_FIT_SQUARE
    : InteractiveMediaVariant.BEST_FIT_WIDE;

  const developmentVideosItems: React.ReactNode[] = [];

  if (developmentVideosData) {
    if (developmentVideosData.primary) {
      const {
        video: { orgimages_id, src_url },
        thumbnail,
      } = developmentVideosData.primary;

      developmentVideosItems.push(
        <DevelopmentVideoInteractiveMedia
          key={orgimages_id}
          {...commonApiProps}
          id={orgimages_id}
          alt="property video"
          status={src_url ? 'idle' : 'loading'}
          variant={interactiveMediaVariant}
          src={src_url}
          thumbnailSrc={thumbnail?.src_url ?? ''}
          isPrimary
        />
      );
    }

    developmentVideosItems.push(
      ...developmentVideosData.nonPrimaries.map(
        ({ video: { orgimages_id, src_url }, thumbnail }) => (
          <DevelopmentVideoInteractiveMedia
            key={orgimages_id}
            {...commonApiProps}
            id={orgimages_id}
            alt="property video"
            status={!src_url ? 'loading' : 'idle'}
            variant={interactiveMediaVariant}
            src={src_url}
            thumbnailSrc={thumbnail?.src_url ?? ''}
          />
        )
      )
    );
  }

  const developmentImagesItems: React.ReactNode[] = [];

  if (developmentImagesData) {
    if (developmentImagesData.primary) {
      developmentImagesItems.push(
        <DevelopmentImageInteractiveMedia
          key={developmentImagesData.primary.orgimages_id}
          {...commonApiProps}
          id={developmentImagesData.primary.orgimages_id}
          alt="development image"
          status="idle"
          variant={interactiveMediaVariant}
          src={developmentImagesData.primary.src_url}
          isPrimary
          description={developmentImagesData.primary.description ?? ''}
          category={developmentImagesData.primary.category}
          isUpdateSuccess={isUpdateDevelopmentImageSuccess}
          isUpdateLoading={isUpdateDevelopmentImageLoading}
          onSubmitButtonClick={({ category, description }) =>
            updateDevelopmentImage({
              ...commonApiProps,
              category,
              description,
              orgImagesId: developmentImagesData.primary!.orgimages_id,
            })
          }
        />
      );
    }

    developmentImagesItems.push(
      ...developmentImagesData.nonPrimaries.map(
        ({ orgimages_id, src_url, category, description }) => (
          <DevelopmentImageInteractiveMedia
            key={orgimages_id}
            {...commonApiProps}
            id={orgimages_id}
            alt="development image"
            status="idle"
            variant={interactiveMediaVariant}
            src={src_url}
            description={description ?? ''}
            category={category}
            isUpdateSuccess={isUpdateDevelopmentImageSuccess}
            isUpdateLoading={isUpdateDevelopmentImageLoading}
            onSubmitButtonClick={({ category, description }) =>
              updateDevelopmentImage({
                ...commonApiProps,
                category,
                description,
                orgImagesId: orgimages_id,
              })
            }
          />
        )
      )
    );
  }

  Object.values(stagingImages).forEach(({ id, preview, status }) => {
    if (status === 'uploading') {
      developmentImagesItems.push(
        <DevelopmentImageInteractiveMedia
          key={id}
          {...commonApiProps}
          id={id}
          alt="property image"
          status="loading"
          variant={interactiveMediaVariant}
          src={preview}
          description=""
          category=""
        />
      );
    }
  });

  const uploadedVideosCount = developmentVideos?.data?.length ?? 0;
  const uploadedImagesCount = developmentImages?.data?.length ?? 0;

  const linkToEditPropertyRoot =
    APP_ROUTES.propertyManagementEditDevelopmentRoot({
      orgId: normalizedOrgId,
      developmentId: normalizedDevelopmentId,
    });

  return (
    <Box className={classes.container}>
      <EditPropertyFormLayout
        title="DEVELOPMENT MEDIA"
        mainPageUrl={linkToEditPropertyRoot}
        formIsLoading={false}
        formSubmitIsLoading={false}
      >
        <FormSection
          title="Development videos"
          description="Upload a video of your development."
        >
          <MediaUploadAreaContainer
            type="video"
            uploadButtonProps={{
              uploadAreaProps: {
                onDrop: onNewVideos,
              },
            }}
          >
            <MediaUploadArea
              variant={
                isMobile || uploadedVideosCount > 0 ? 'simplified' : 'full'
              }
              layout={isMobile ? 'equal' : 'cover'}
              icon={<VideoCall fontSize="inherit" color="inherit" />}
              hintTitleFullVariant="Drag your videos here"
              hintSubtitleFullVariant="Add at least 1 video"
              uploadAreaProps={{
                onDrop: onNewVideos,
                dropzoneOpts: {
                  accept: ACCEPTABLE_VIDEO_MIME_TYPES,
                  maxSize: MAX_VIDEO_FILE_SIZE,
                  maxFiles: MAX_VIDEO_FILES_AT_ONCE,
                },
              }}
              items={developmentVideosItems}
            />
          </MediaUploadAreaContainer>
        </FormSection>

        <FormSection
          title="Development images"
          description="Upload photos of your common areas and shared facilities."
        >
          <MediaUploadAreaContainer
            type="image"
            uploadButtonProps={{
              uploadAreaProps: {
                onDrop: onNewImages,
              },
            }}
          >
            <MediaUploadArea
              variant={
                isMobile || uploadedImagesCount > 0 ? 'simplified' : 'full'
              }
              layout={isMobile ? 'equal' : 'cover'}
              icon={<Image fontSize="inherit" color="inherit" />}
              hintTitleFullVariant="Drag your photos here"
              hintSubtitleFullVariant="Add at least 5 photos"
              uploadAreaProps={{
                onDrop: onNewImages,
                dropzoneOpts: {
                  accept: ACCEPTABLE_DEVELOPMENT_IMAGE_MIME_TYPES,
                  maxSize: MAX_DEVELOPMENT_IMAGE_FILE_SIZE,
                  maxFiles: MAX_DEVELOPMENT_IMAGE_FILES_AT_ONCE,
                },
              }}
              items={developmentImagesItems}
            />
          </MediaUploadAreaContainer>
        </FormSection>
      </EditPropertyFormLayout>

      <Snackbar
        hideSnackbar={hideSnackbar}
        show={snackbarState.show}
        type={snackbarState.type}
      >
        <>{snackbarState.content}</>
      </Snackbar>
    </Box>
  );
}
