import { BooleanString, ConversionVariant } from './types';

function apiValueToFormValue(
  value: string | null | undefined,
  conversionVariant: 'string'
): string;
function apiValueToFormValue(
  value: number | null | undefined,
  conversionVariant: 'number'
): string;
function apiValueToFormValue(
  value: boolean | null | undefined,
  conversionVariant: 'boolean'
): BooleanString;
function apiValueToFormValue(
  value: string | boolean | number | null | undefined,
  conversionVariant: ConversionVariant
): string | BooleanString {
  switch (conversionVariant) {
    case 'boolean': {
      return value === null || value === undefined
        ? ''
        : value
        ? 'true'
        : 'false';
    }
    case 'string':
    case 'number':
    default:
      return value !== null && value !== undefined ? `${value}` : '';
  }
}

export default apiValueToFormValue;
