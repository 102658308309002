import * as React from 'react';
import { Box } from '@material-ui/core';
import { BasicInformationTab } from '../types';

import useStyles from './MobileTabsNavigator.styles';
import clsx from 'clsx';
import { Typography } from '../../../../../../../atoms';

export interface MobileTabsNavigatorProps {
  currentTab: BasicInformationTab;
  setCurrentTab: (newTab: BasicInformationTab) => void;
}

export default function MobileTabsNavigator({
  currentTab,
  setCurrentTab,
}: MobileTabsNavigatorProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <button
        className={clsx(
          classes.tabButton,
          currentTab === BasicInformationTab.BASIC_INFORMATION && 'selected'
        )}
        onClick={() => setCurrentTab(BasicInformationTab.BASIC_INFORMATION)}
      >
        <Typography className={classes.tabButtonText}>
          {BasicInformationTab.BASIC_INFORMATION}
        </Typography>
      </button>

      <button
        className={clsx(
          classes.tabButton,
          currentTab === BasicInformationTab.LOCATION && 'selected'
        )}
        onClick={() => setCurrentTab(BasicInformationTab.LOCATION)}
      >
        <Typography className={classes.tabButtonText}>
          {BasicInformationTab.LOCATION}
        </Typography>
      </button>

      <button
        className={clsx(
          classes.tabButton,
          currentTab === BasicInformationTab.CONTACT && 'selected'
        )}
        onClick={() => setCurrentTab(BasicInformationTab.CONTACT)}
      >
        <Typography className={classes.tabButtonText}>
          {BasicInformationTab.CONTACT}
        </Typography>
      </button>
    </Box>
  );
}
