import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PvtFetcherBaseProps } from '../../utils/pvtFetcher';
import { FlatActivityInDb, FlatInDb } from '../../property/types';
import { useFlatUrlCreator } from './useFlat';

interface FetcherProps extends PvtFetcherBaseProps {
  developmentId: number;
  flatId: number;

  imageId: string;
}

interface FetcherResponse {
  data: FlatInDb;
  meta: {
    activity: FlatActivityInDb;
    imageId: string;
  };
}

export async function updateFlatImageThumbnail({
  authToken,
  orgId,
  developmentId,
  flatId,
  imageId,
}: FetcherProps): Promise<FetcherResponse> {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'BTR-Organization': `${orgId}`,
    Authorization: `Bearer ${authToken}`,
  };

  const res = await fetch(
    `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/${flatId}/imagethumbnail`,
    {
      method: 'PUT',
      headers,
      credentials: 'include',
      body: JSON.stringify({
        imageId,
      }),
    }
  );

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return res.json() as Promise<FetcherResponse>;
}

export default function useUpdateFlatImageThumbnail() {
  const queryClient = useQueryClient();

  return useMutation<FetcherResponse, Error, FetcherProps>(
    updateFlatImageThumbnail,
    {
      onSuccess: (data, variables) => {
        void queryClient.setQueryData(
          [
            useFlatUrlCreator({
              developmentId: variables.developmentId,
              flatId: variables.flatId,
            }),
            { authToken: variables.authToken, orgId: variables.orgId },
          ],
          { data: data.data }
        );
      },
    }
  );
}
