import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Snackbar, SnackbarType } from '../../../../../../atoms';
import { SpecialTabInfo, SpecialTabs } from '../../../../../../molecules';
import { EditPropertyFormLayout } from '../../../../../../layout';

import useFlatIds, {
  FetcherResponse as UseFlatIdsResponse,
} from '../../../../../../../api/developments/useFlatIds';
import useUpdateFlat from '../../../../../../../api/developments/flats/useUpdateFlat';
import useAuthToken from '../../../../../../../store/auth/hooks/useAuthToken';

import { useSnackbar } from '../../../../../../../hooks';
import { navigate } from '../../../../../../../utils/dom';
import { APP_ROUTES } from '../../../../../../../config/app';

import { SnackbarContent } from '../../components';
import PropertyForm from './PropertyForm';
import { BasicInformationFieldValues } from './BasicInformationPropertyForm';

type FlatData = UseFlatIdsResponse['data'];

// Create a sorted version of the flat array
const getSortedFlatData = (flatData: FlatData): FlatData => {
  if (flatData.length > 0) {
    return [...flatData].sort(
      (flatDataA, flatDataB) => flatDataA.flat_id - flatDataB.flat_id
    );
  }

  return flatData;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#F3F3F3',
      padding: theme.spacing(3, 0),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0),
      },
    },
    pcWrapTabs: {
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    pcTab: {
      fontSize: theme.typography.pxToRem(18),
      borderRadius: theme.spacing(1.25, 1.25, 0, 0),
      backgroundColor: '#EBEAEA',
      color: theme.palette.black.main,
      width: 200,
      padding: theme.spacing(1.5, 3),
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.contrastText,
      },
    },
  })
);

export default function PropertiesSubPage({
  developmentId,
  orgId,
  flatId,
}: RouteComponentProps<{
  developmentId: string;
  orgId: string;
  flatId: string;
}>) {
  const normalizedOrgId = Number(orgId) ?? 0;
  const normalizedDevelopmentId = Number(developmentId) ?? 0;
  const normalizedFlatId = flatId ? Number(flatId) : 0;

  const classes = useStyles();
  const authToken = useAuthToken();
  const [selectedFlatId, setSelectedFlatId] = React.useState<number | string>(
    normalizedFlatId
  );
  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const { data: flatIdsResponse, isLoading: flatIdsLoading } = useFlatIds({
    authToken,
    orgId: normalizedOrgId,
    developmentId: normalizedDevelopmentId,
  });

  const { mutate: updateFlat, isLoading: updatingFlatStatus } = useUpdateFlat();

  const formSubmitHandlerOnSuccess = () => {
    displaySnackbar(
      SnackbarType.NORMAL,
      <SnackbarContent text="Your information has been successfully saved" />
    );
  };

  const formSubmitHandlerOnError = (error: Error) => {
    displaySnackbar(
      SnackbarType.ERROR,
      <SnackbarContent
        text={`Failed to update development. Reason: ${error.message}`}
      />
    );
  };

  const basicInformationFormSubmitHandler: SubmitHandler<
    BasicInformationFieldValues
  > = (formData) => {
    const flatUpdater = { ...formData[Number(selectedFlatId)] };

    if (!flatUpdater.rent_pcm || !Number(flatUpdater.rent_pcm)) {
      flatUpdater.rent_pcm = null;
    }

    void updateFlat(
      {
        authToken,
        orgId: normalizedOrgId,
        developmentId: normalizedDevelopmentId,
        flatId: Number(selectedFlatId),
        updater: flatUpdater,
      },
      {
        onSuccess: formSubmitHandlerOnSuccess,
        onError: formSubmitHandlerOnError,
      }
    );
  };

  const {
    control: basicInformationFormControl,
    handleSubmit: basicInformationFormSubmitFactory,
    setValue: basicInformationFormSetValue,
    formState: { errors: basicInformationFormErrors },
  } = useForm<BasicInformationFieldValues>();

  const basicInformationFormSubmit = basicInformationFormSubmitFactory(
    basicInformationFormSubmitHandler
  );

  const sortedFlatData = flatIdsResponse
    ? getSortedFlatData(flatIdsResponse.data)
    : [];

  console.log(basicInformationFormErrors);

  const flatTabInfos: SpecialTabInfo[] = sortedFlatData.map((flat) => ({
    key: flat.flat_id,
    label: flat.flat_title,
    tabPanelContent: (
      <PropertyForm
        authToken={authToken}
        orgId={normalizedOrgId}
        developmentId={normalizedDevelopmentId}
        flatId={flat.flat_id}
        formControl={basicInformationFormControl}
        formSetValue={basicInformationFormSetValue}
        formSubmitHandler={basicInformationFormSubmit}
        // TODO
        // @ts-ignore
        formErrors={basicInformationFormErrors}
      />
    ),
  }));

  const linkToEditPropertyRoot =
    APP_ROUTES.propertyManagementEditDevelopmentRoot({
      orgId: normalizedOrgId,
      developmentId: normalizedDevelopmentId,
    });

  const onChangeFlatTab = (e: React.ChangeEvent, flatId: number) => {
    setSelectedFlatId(flatId);
  };

  React.useEffect(() => {
    if (selectedFlatId) {
      void navigate(
        APP_ROUTES.propertyManagementEditDevelopmentProperty({
          orgId: normalizedOrgId,
          developmentId: normalizedDevelopmentId,
          flatId: Number(selectedFlatId),
        })
      );
    }
  }, [selectedFlatId]);

  React.useEffect(() => {
    if (sortedFlatData.length > 0) {
      const flatId = normalizedFlatId;
      if (
        !flatId ||
        !sortedFlatData.find((flatData) => flatData.flat_id === flatId)
      ) {
        setSelectedFlatId(sortedFlatData[0].flat_id);
      }
    }
  }, [JSON.stringify(sortedFlatData)]);

  const pageIsLoading =
    flatIdsLoading || sortedFlatData.length === 0 || selectedFlatId === 0;

  return (
    <Box className={classes.container}>
      <EditPropertyFormLayout
        title="PROPERTIES"
        mainPageUrl={linkToEditPropertyRoot}
        formIsLoading={pageIsLoading}
        formSubmitHandler={basicInformationFormSubmit}
        formSubmitIsLoading={updatingFlatStatus}
      >
        <SpecialTabs
          tabs={flatTabInfos}
          activeTab={selectedFlatId}
          onChangeTab={onChangeFlatTab}
        />
      </EditPropertyFormLayout>
      <Snackbar
        hideSnackbar={hideSnackbar}
        show={snackbarState.show}
        type={snackbarState.type}
      >
        <>{snackbarState.content}</>
      </Snackbar>
    </Box>
  );
}
