import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFlatImagesUrlCreator } from './useFlatImages';
import { PvtFetcherBaseProps } from '../../utils/pvtFetcher';
import { FlatActivityInDb } from '../../property/types';
import { OrgFile } from '../../orgs/types';
import { CustomError, ErrorObjFromApi } from '../../../utils/error';

interface FetcherProps extends PvtFetcherBaseProps {
  developmentId: number;
  flatId: number;
  imageId: string; // UUID
}

interface FetcherResponse {
  data: {
    storageResult: any;
    databaseResult: {
      newFlatImages: OrgFile[];
      deletedImage: OrgFile;
    };
  };
  meta: {
    activity: FlatActivityInDb;
  };
}

export async function deleteFlatImage({
  authToken,
  orgId,
  developmentId,
  flatId,
  imageId,
}: FetcherProps): Promise<FetcherResponse> {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'BTR-Organization': `${orgId}`,
    Authorization: `Bearer ${authToken}`,
  };

  const res = await fetch(
    `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/${flatId}/image/${imageId}`,
    {
      method: 'DELETE',
      headers,
      credentials: 'include',
    }
  );

  if (!res.ok) {
    const errObj = (await res.json()) as ErrorObjFromApi;
    throw new CustomError({
      message: errObj.message,
      meta: errObj.meta,
    });
  }

  return res.json() as Promise<FetcherResponse>;
}

export default function useDeleteFlatImage() {
  const queryClient = useQueryClient();
  return useMutation<FetcherResponse, CustomError, FetcherProps>(
    deleteFlatImage,
    {
      onSuccess: (data, variables) => {
        void queryClient.setQueryData(
          [
            useFlatImagesUrlCreator({
              developmentId: variables.developmentId,
              flatId: variables.flatId,
            }),
            { authToken: variables.authToken, orgId: variables.orgId },
          ],
          { data: data.data.databaseResult.newFlatImages }
        );
      },
    }
  );
}
