import * as React from 'react';
import { Router } from '@reach/router';
import {
  AmenitiesSubPage,
  BasicInformationSubPage,
  DevelopmentEditPage,
  DevelopmentMediasSubPage,
  FacilitiesSubPage,
  MobileNavigationSubPage,
  PropertiesSubPage,
  TenancyTermsSubPage,
  PublicListingPage,
} from '../../../../components/pages/my-account/property/edit';

export default function MyAccountPropertyEditPage() {
  return (
    <Router basepath="/my-account/property/edit">
      <DevelopmentEditPage path="/:orgId/:developmentId">
        <MobileNavigationSubPage path="/" />
        <AmenitiesSubPage path="amenities" />
        <BasicInformationSubPage path="basic-information" />
        <DevelopmentMediasSubPage path="media" />
        <FacilitiesSubPage path="facilities" />
        <PropertiesSubPage path="properties" />
        <PropertiesSubPage path="properties/:flatId" />
        <TenancyTermsSubPage path="tenancy-terms" />
        <PublicListingPage path="public-listing" />
      </DevelopmentEditPage>
    </Router>
  );
}
