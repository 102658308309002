import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DevelopmentV2 } from '../../property/types';

interface FetcherProps {
  authToken: string;
  orgId: number;
  developmentId: number;
  flatId: number;
  updater: Partial<DevelopmentV2>;
}

interface FetcherResponse {
  data: DevelopmentV2;
}

export async function updateFlat({
  authToken,
  orgId,
  developmentId,
  flatId,
  updater,
}: FetcherProps): Promise<FetcherResponse> {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'BTR-Organization': `${orgId}`,
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const res = await fetch(
    `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/${flatId}`,
    {
      method: 'PATCH',
      headers,
      credentials: 'include',
      body: JSON.stringify(updater),
    }
  );

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return res.json() as Promise<FetcherResponse>;
}

export default function useUpdateFlat() {
  const queryClient = useQueryClient();

  return useMutation<FetcherResponse, Error, FetcherProps>(updateFlat, {
    onSuccess: (data, variables) => {
      void queryClient.setQueryData(
        [
          `${process.env.GATSBY_API_HOST}/api/development/${variables.developmentId}/${variables.flatId}`,
          { authToken: variables.authToken, orgId: variables.orgId },
        ],
        { data: data.data }
      );
    },
  });
}
