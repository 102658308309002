import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.primary.contrastText,
        padding: theme.spacing(5, 0),
      },
    },
    center: {
      textAlign: 'center',
    },
    skeleton: {
      margin: theme.spacing(0, 2),
      width: 'calc(100% - 32px)',
    },
  })
);

export default useStyles;
