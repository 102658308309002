import pvtFetcher, { PvtFetcherProps } from '../utils/pvtFetcher';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { LandlordV2 } from '../landlords/types';
import API_ENDPOINTS from '../endpoints';

type LandlordData = Pick<
  LandlordV2,
  'landlordId' | 'landlordName' | 'landlordLogoSrc'
>;

interface GetOrgLandlordsFetcherResponse {
  data: LandlordData[];
}

export type UseOrgLandlordsResponse = LandlordData[];

export interface UseOrgLandlordsProps extends PvtFetcherProps {
  queryConfig?: UseQueryOptions<UseOrgLandlordsResponse, Error>;
}

/**
 * Return all landlords belonging to an organization.
 */
export default function useOrgLandlords({
  authToken,
  orgId,
  queryConfig,
}: UseOrgLandlordsProps) {
  const url = API_ENDPOINTS.GET_ORGANIZATION_LANDLORDS;

  return useQuery<UseOrgLandlordsResponse>(
    [url, { authToken, orgId }],
    async () => {
      const { data } = await pvtFetcher<GetOrgLandlordsFetcherResponse>(url, {
        authToken,
        orgId,
      });

      return data;
    },
    queryConfig
  );
}
