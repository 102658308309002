import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RouteComponentProps } from '@reach/router';
import { Box } from '@material-ui/core';
import { Snackbar, SnackbarType } from '../../../../../../atoms';
import { EditPropertyFormLayout } from '../../../../../../layout';

import useDevelopment from '../../../../../../../api/developments/useDevelopment';
import useUpdateDevelopment from '../../../../../../../api/developments/useUpdateDevelopment';

import useAuthToken from '../../../../../../../store/auth/hooks/useAuthToken';
import { useSnackbar } from '../../../../../../../hooks';
import { APP_ROUTES } from '../../../../../../../config/app';

import useStyles from './AmenitiesSubPage.styles';
import attributesInputData from './attributesInputData';
import { AmenitiesFieldValues } from './types';
import { AttributesSelects, SnackbarContent } from '../../components';

export default function AmenitiesSubPage({
  orgId,
  developmentId,
}: RouteComponentProps<{ developmentId: string; orgId: string }>) {
  const normalizedOrgId = Number(orgId) ?? 0;
  const normalizedDevelopmentId = Number(developmentId) ?? 0;

  const classes = useStyles();
  const authToken = useAuthToken();
  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const {
    data: developmentResponse,
    isSuccess: developmentLoaded,
    isLoading: developmentLoading,
  } = useDevelopment({
    authToken,
    orgId: normalizedOrgId,
    developmentId: normalizedDevelopmentId,
  });

  const { mutate: updateDevelopment, isLoading: updatingDevelopmentStatus } =
    useUpdateDevelopment();

  const formSubmitHandlerOnSuccess = () => {
    displaySnackbar(
      SnackbarType.NORMAL,
      <SnackbarContent text="Your information has been successfully saved" />
    );
  };

  const formSubmitHandlerOnError = (error: Error) => {
    displaySnackbar(
      SnackbarType.ERROR,
      <SnackbarContent
        text={`Failed to update development. Reason: ${error.message}`}
      />
    );
  };

  const amenitiesFormSubmitHandler: SubmitHandler<AmenitiesFieldValues> = (
    formData
  ) => {
    Object.keys(formData).forEach((key) => {
      formData[key] =
        String(formData[key] ?? '')?.trim() === '' ? null : formData[key];
    });

    void updateDevelopment(
      {
        authToken,
        orgId: normalizedOrgId,
        developmentId: normalizedDevelopmentId,
        updater: formData,
      },
      {
        onSuccess: formSubmitHandlerOnSuccess,
        onError: formSubmitHandlerOnError,
      }
    );
  };

  const {
    control: amenitiesFormControl,
    handleSubmit: amenitiesFormSubmitFactory,
  } = useForm<AmenitiesFieldValues>();

  const amenitiesFormSubmit = amenitiesFormSubmitFactory(
    amenitiesFormSubmitHandler
  );

  const linkToEditPropertyRoot =
    APP_ROUTES.propertyManagementEditDevelopmentRoot({
      orgId: normalizedOrgId,
      developmentId: normalizedDevelopmentId,
    });

  return (
    <Box className={classes.pageCtn}>
      <EditPropertyFormLayout
        title="AMENITIES"
        mainPageUrl={linkToEditPropertyRoot}
        formIsLoading={developmentLoading}
        formSubmitHandler={amenitiesFormSubmit}
        formSubmitIsLoading={updatingDevelopmentStatus}
      >
        {developmentLoaded && (
          <AttributesSelects
            development={developmentResponse?.data}
            attributes={attributesInputData}
            attributeTitle="Amenities"
            attributeDescription="Please select the amenities each property will offer"
            formControl={amenitiesFormControl}
            formSubmitHandler={amenitiesFormSubmit}
          />
        )}
        <Snackbar
          hideSnackbar={hideSnackbar}
          show={snackbarState.show}
          type={snackbarState.type}
        >
          <>{snackbarState.content}</>
        </Snackbar>
      </EditPropertyFormLayout>
    </Box>
  );
}
