import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { Snackbar, SnackbarType } from '../../../../../../atoms';
import { EditPropertyFormLayout } from '../../../../../../layout';

import useAuthToken from '../../../../../../../store/auth/hooks/useAuthToken';
import useDevelopment from '../../../../../../../api/developments/useDevelopment';
import useUpdateDevelopment from '../../../../../../../api/developments/useUpdateDevelopment';

import { useSnackbar } from '../../../../../../../hooks';
import { APP_ROUTES } from '../../../../../../../config/app';
import { ScreenType } from '../../../../../../../config';
import useIsScreenType from '../../../../../../../utils/dom/useIsScreenType';

import apiValueToFormValue from '../../utils/apiValueToFormValue';
import { SnackbarContent } from '../../components';
import parseFormData from './utils/parseFormData';
import { FeesForm } from './TenancyTermsForm';
import useStyles from './TenancyTermsSubPage.styles';
import { TenancyTermsFormValues } from './types';

export default function TenancyTermsSubPage({
  developmentId,
  orgId,
}: RouteComponentProps<{ developmentId: string; orgId: string }>) {
  const normalizedOrgId = Number(orgId) ?? 0;
  const normalizedDevelopmentId = Number(developmentId) ?? 0;

  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);

  const classes = useStyles({ isMobile });

  const authToken = useAuthToken();
  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const { data: development, status: developmentStatus } = useDevelopment({
    authToken,
    orgId: normalizedOrgId,
    developmentId: normalizedDevelopmentId,
  });

  const isPageDataLoading = developmentStatus === 'loading';

  const tenancyTermsFormDefaultValues: TenancyTermsFormValues | undefined =
    development
      ? {
          min_term: development.data.min_term ?? '',
          max_term: development.data.max_term ?? '',
          notice_period: development.data.notice_period ?? '',
          break_clause: development.data.break_clause ?? '',
          deposit_term: development.data.deposit_term ?? '',
          zero_deposit: apiValueToFormValue(
            development.data.zero_deposit,
            'boolean'
          ),
        }
      : undefined;

  const { mutate: updateDevelopment, status: updateDevelopmentStatus } =
    useUpdateDevelopment();

  const formSubmitHandlerOnSuccess = () => {
    displaySnackbar(
      SnackbarType.NORMAL,
      <SnackbarContent text="Your information has been successfully saved" />
    );
  };

  const formSubmitHandlerOnError = (error: Error) => {
    displaySnackbar(
      SnackbarType.ERROR,
      <SnackbarContent
        text={`Failed to update development. Reason: ${error.message}`}
      />
    );
  };

  const tenancyTermsFormSubmitHandler: SubmitHandler<TenancyTermsFormValues> = (
    formData
  ) => {
    const { developmentUpdater } = parseFormData(formData);

    void updateDevelopment(
      {
        authToken,
        orgId: normalizedOrgId,
        developmentId: normalizedDevelopmentId,
        updater: developmentUpdater,
      },
      {
        onSuccess: formSubmitHandlerOnSuccess,
        onError: formSubmitHandlerOnError,
      }
    );
  };

  const {
    control: tenancyTermsFormControl,
    handleSubmit: tenancyTermsFormSubmitFactory,
  } = useForm<TenancyTermsFormValues>();

  const tenancyTermsFormSubmit = tenancyTermsFormSubmitFactory(
    tenancyTermsFormSubmitHandler
  );

  const tenancyTermsFormSubmitIsLoading = updateDevelopmentStatus === 'loading';

  const linkToEditPropertyRoot =
    APP_ROUTES.propertyManagementEditDevelopmentRoot({
      orgId: normalizedOrgId,
      developmentId: normalizedDevelopmentId,
    });

  return (
    <Box className={classes.pageCtn}>
      <EditPropertyFormLayout
        title="TENANCY TERMS"
        mainPageUrl={linkToEditPropertyRoot}
        formIsLoading={isPageDataLoading}
        formSubmitHandler={tenancyTermsFormSubmit}
        formSubmitIsLoading={tenancyTermsFormSubmitIsLoading}
      >
        <FeesForm
          defaultValues={tenancyTermsFormDefaultValues}
          formControl={tenancyTermsFormControl}
          formSubmit={tenancyTermsFormSubmit}
          formSubmitIsLoading={tenancyTermsFormSubmitIsLoading}
        />

        <Snackbar
          hideSnackbar={hideSnackbar}
          show={snackbarState.show}
          type={snackbarState.type}
        >
          <>{snackbarState.content}</>
        </Snackbar>
      </EditPropertyFormLayout>
    </Box>
  );
}
