import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import pvtFetcher, { PvtFetcherBaseProps } from '../utils/pvtFetcher';
import { OrgFile } from '../orgs/types';

interface UseDevelopmentVideosProps extends PvtFetcherBaseProps {
  developmentId: number;
  queryOpts?: UseQueryOptions<FetcherResponse, Error>;
}

export interface VideoData {
  video: OrgFile;
  thumbnail?: OrgFile | undefined;
}

interface FetcherResponse {
  data: VideoData[];
}

export default function useDevelopmentVideos({
  developmentId,
  queryOpts,
  ...pvtFetcherProps
}: UseDevelopmentVideosProps) {
  const url = `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/videos`;

  return useQuery<FetcherResponse, Error>(
    [url],
    () => pvtFetcher<FetcherResponse>(url, pvtFetcherProps),
    queryOpts
  );
}
