import * as React from 'react';
import { Controller } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { MarkdownEditor, Select, TextField } from '../../../../../../../atoms';
import { DevelopmentType } from '../../../../../../../../models/developments';
import useIsScreenType from '../../../../../../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../../../../../../config';

import { FormSection } from '../../../components';
import { BaseFormProps, BasicInformationFieldValues } from '../types';
import useStyles from './BasicInformationForm.styles';
import { isStringAllDigits } from '../../../../../../../../utils/validators';

export interface BasicInformationFormProps
  extends BaseFormProps<BasicInformationFieldValues> {
  orgLandlords: { landlordId: number; landlordName: string }[];
}

export default function BasicInformationForm({
  defaultValues,
  formControl,
  formSubmit,
  formSubmitIsLoading,
  formErrors,
  orgLandlords,
}: BasicInformationFormProps) {
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({ isMobile });

  const hasOrgLandlords = orgLandlords.length > 0;

  // This controller appears in different position depending on whether we are
  // in mobile view or not.
  const aboutYourDevelopmentController = (
    <Controller<BasicInformationFieldValues>
      name="developments_details_content"
      control={formControl}
      defaultValue={defaultValues?.developments_details_content ?? ''}
      render={({ field }) => (
        <MarkdownEditor
          label="About your development"
          onChange={field.onChange}
          value={`${field.value}`}
          helperTooltip="A detailed introduction about your development. We recommend referring to your location, development facilities (common to share between all residents), each property's amenities and terms of tenancy. Around 500 words."
        />
      )}
    />
  );

  return (
    <FormSection title="Basic information" formOnSubmit={formSubmit}>
      <Box className={classes.formControllersCtn}>
        <Controller<BasicInformationFieldValues>
          name="title"
          control={formControl}
          defaultValue={defaultValues?.title ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Title"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />

        <Controller<BasicInformationFieldValues>
          name="description_free_text"
          control={formControl}
          defaultValue={defaultValues?.description_free_text ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Summary"
              fullWidth
              disabled={formSubmitIsLoading}
              helperTooltip="One brief introduction sentence to your development. Max 50 words."
            />
          )}
        />

        {isMobile && aboutYourDevelopmentController}

        <Controller<BasicInformationFieldValues>
          name="development_type"
          control={formControl}
          defaultValue={defaultValues?.development_type ?? ''}
          render={({ field }) => (
            <Select
              {...field}
              fullWidth
              label="Development type"
              options={[
                { value: '', label: ' ' },
                {
                  value: DevelopmentType.APARTMENTS,
                  label: DevelopmentType.APARTMENTS,
                },
                {
                  value: DevelopmentType.HOUSES,
                  label: DevelopmentType.HOUSES,
                },
                {
                  value: DevelopmentType.HOUSES_AND_APARTMENTS,
                  label: DevelopmentType.HOUSES_AND_APARTMENTS,
                },
              ]}
              disabled={formSubmitIsLoading}
            />
          )}
        />

        <Controller<BasicInformationFieldValues>
          name="units_count"
          control={formControl}
          defaultValue={defaultValues?.units_count ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Units count"
              fullWidth
              type="number"
              disabled={formSubmitIsLoading}
            />
          )}
        />

        {!isMobile && (
          <Box className={classes.aboutYourDevelopmentNonMobileWrapper}>
            {aboutYourDevelopmentController}
          </Box>
        )}

        <Controller<BasicInformationFieldValues>
          name="development_website"
          control={formControl}
          defaultValue={defaultValues?.development_website ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Development website"
              placeholder="https://my-development.com"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />

        <Controller<BasicInformationFieldValues>
          name="virtual_tour_url"
          control={formControl}
          defaultValue={defaultValues?.virtual_tour_url ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Virtual tour URL"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />

        <Controller<BasicInformationFieldValues>
          name="manager_id"
          control={formControl}
          defaultValue={defaultValues?.manager_id ?? 0}
          render={({ field }) => (
            <Select
              {...field}
              fullWidth
              label="Manager"
              options={[
                { value: 0, label: ' ' },
                ...orgLandlords.map(({ landlordId, landlordName }) => ({
                  value: landlordId,
                  label: landlordName,
                })),
              ]}
              disabled={formSubmitIsLoading || !hasOrgLandlords}
            />
          )}
        />

        <Controller<BasicInformationFieldValues>
          name="completion_date"
          control={formControl}
          defaultValue={defaultValues?.completion_date ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Completion year"
              fullWidth
              disabled={formSubmitIsLoading}
              error={!!formErrors?.completion_date}
              helperText={formErrors?.completion_date?.message}
            />
          )}
          rules={{
            validate: {
              digitsOnly: (value) =>
                value !== '' && !isStringAllDigits(`${value}`)
                  ? 'Only digits are allowed'
                  : true,
              yearWithinRange: (value) =>
                value !== '' && (value < 1000 || value > 9999)
                  ? 'Year must be between 1000 and 9999'
                  : true,
            },
          }}
        />
      </Box>
    </FormSection>
  );
}
