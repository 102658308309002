import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileNavigationSubPageCtn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
      padding: theme.spacing(2.75, 2),
    },

    subItemsCtn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),

      padding: theme.spacing(0, 2),
    },

    item: {
      padding: theme.spacing(1.5),
      backgroundColor: theme.palette.common.white,
      borderRadius: '4px',
    },

    headerItem: {},

    subItem: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(1),
    },
  })
);

export default useStyles;
