import { Box } from '@material-ui/core';
import * as React from 'react';
import { Controller } from 'react-hook-form';
import { ScreenType } from '../../../../../../../../config';
import useIsScreenType from '../../../../../../../../utils/dom/useIsScreenType';
import { TextField } from '../../../../../../../atoms';
import { FormSection } from '../../../components';
import { BaseFormProps, DevelopmentContactFieldValues } from '../types';
import useStyles from './DevelopmentContactFormMobile.styles';

export type DevelopmentContactFormMobileProps =
  BaseFormProps<DevelopmentContactFieldValues>;

export default function DevelopmentContactFormMobile({
  defaultValues,
  formControl,
  formSubmit,
  formSubmitIsLoading,
}: DevelopmentContactFormMobileProps) {
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({ isMobile });

  return (
    <FormSection title="Contact" formOnSubmit={formSubmit}>
      <Box className={classes.formControllersCtn}>
        <Controller<DevelopmentContactFieldValues>
          name="contact_name"
          control={formControl}
          defaultValue={defaultValues?.contact_name ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Name"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />
        <Controller<DevelopmentContactFieldValues>
          name="contact_job_title"
          control={formControl}
          defaultValue={defaultValues?.contact_job_title ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Title"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />
        <Controller<DevelopmentContactFieldValues>
          name="contact_email"
          control={formControl}
          defaultValue={defaultValues?.contact_email ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />
        <Controller<DevelopmentContactFieldValues>
          name="contact_phone"
          control={formControl}
          defaultValue={defaultValues?.contact_phone ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Phone"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />
      </Box>
    </FormSection>
  );
}
