import * as React from 'react';
import { Box } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { Select, SelectOption, TextField } from '../../../../../../../atoms';

import useIsScreenType from '../../../../../../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../../../../../../config';

import { FormSection } from '../../../components';
import { BaseFormProps } from '../../BasicInformationSubPage/types';
import { TenancyTermsFormValues } from '../types';
import useStyles from './TenancyTermsForm.styles';

export type TenancyTermsFormProps = BaseFormProps<TenancyTermsFormValues>;

const zeroDepositOptions: SelectOption[] = [
  {
    label: '--',
    value: '',
  },
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export default function TenancyTermsForm({
  defaultValues,
  formControl,
  formSubmit,
  formSubmitIsLoading,
}: TenancyTermsFormProps) {
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({ isMobile });

  return (
    <FormSection title="Tenancy Terms" formOnSubmit={formSubmit}>
      <Box className={classes.formControllersCtn}>
        <Controller
          name="min_term"
          control={formControl}
          defaultValue={defaultValues?.min_term ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Min term"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />

        <Controller
          name="max_term"
          control={formControl}
          defaultValue={defaultValues?.max_term ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Max term"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />

        <Controller
          name="notice_period"
          control={formControl}
          defaultValue={defaultValues?.notice_period ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Notice period"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />

        <Controller
          name="break_clause"
          control={formControl}
          defaultValue={defaultValues?.break_clause ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Break clause"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />

        <Controller
          name="deposit_term"
          control={formControl}
          defaultValue={defaultValues?.deposit_term ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Deposit"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />

        <Controller
          name="zero_deposit"
          control={formControl}
          defaultValue={defaultValues?.zero_deposit ?? ''}
          render={({ field }) => (
            <Select
              {...field}
              label="Zero deposit"
              options={zeroDepositOptions}
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />
      </Box>
    </FormSection>
  );
}
