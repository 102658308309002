import { Development } from '../../../../../../../../models/developments';
import { BasicInformationFormFieldValues } from '../types';
import { completionYearStringToDate } from './parseCompletionDate';

export default function parseFormData(
  formData: BasicInformationFormFieldValues
): {
  developmentUpdater: Partial<Development>;
  developmentDetailsUpdater: { developments_details_content: string };
} {
  const developmentUpdater = Object.entries(formData).reduce<
    Partial<Development>
  >((parsedFormData, [propertyKey, propertyValue]) => {
    if (propertyKey === 'units_count') {
      parsedFormData[propertyKey] = Number(propertyValue);
    } else if (propertyKey === 'owner_id' || propertyKey === 'manager_id') {
      if (propertyValue === 0) {
        parsedFormData[propertyKey] = null;
      } else {
        parsedFormData[propertyKey] = propertyValue;
      }
    } else if (propertyKey === 'completion_date') {
      parsedFormData[propertyKey] = completionYearStringToDate(
        propertyValue as string
      );
    } else if (propertyKey !== 'developments_details_content') {
      parsedFormData[propertyKey] = propertyValue;
    }

    return parsedFormData;
  }, {});

  const developmentDetailsUpdater = {
    developments_details_content: formData.developments_details_content,
  };

  return {
    developmentUpdater,
    developmentDetailsUpdater,
  };
}
