import { TenancyTermsFormValues } from '../types';
import { DevelopmentFeesAttributes } from '../../../../../../../../models/developments';
import formValueToApiValue from '../../../utils/formValueToApiValue';

type ParsedFormData = Omit<DevelopmentFeesAttributes, 'short_rent'>;

export default function parseFormData(formData: TenancyTermsFormValues): {
  developmentUpdater: ParsedFormData;
} {
  const developmentUpdater = Object.entries(formData).reduce<ParsedFormData>(
    (parsedFormData, [propertyKey, propertyValue]) => {
      if (propertyKey === 'zero_deposit') {
        parsedFormData[propertyKey] = formValueToApiValue(
          propertyValue,
          'boolean'
        );
      } else {
        parsedFormData[propertyKey] = formValueToApiValue(
          propertyValue,
          'string'
        );
      }

      return parsedFormData;
    },
    {}
  );

  return {
    developmentUpdater,
  };
}
