export function completionDateStringToYear(
  completionDateString: string | null
): string {
  if (completionDateString && completionDateString !== 'null') {
    try {
      const date = new Date(completionDateString);
      return `${date.getFullYear()}`;
    } catch (e) {
      return '';
    }
  }
  return '';
}

export function completionYearStringToDate(
  completionYearString: string
): string | null {
  if (!completionYearString) {
    return null;
  }

  try {
    return new Date(`${completionYearString}-01-01`).toISOString();
  } catch (err) {
    return null;
  }
}
