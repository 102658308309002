import { SelectWithTitleAndIconProps } from '../../../../../../molecules';

const attributesInputData: Pick<
  SelectWithTitleAndIconProps,
  'title' | 'icon' | 'options'
>[] = [
  {
    title: 'Concierge',
    icon: 'attr_concierge',
  },
  {
    title: 'Parcel collection/storage',
    icon: 'attr_parcel',
  },
  {
    title: 'Onsite maintenance',
    icon: 'attr_onsite_maintenance',
  },
  {
    title: 'Onsite management',
    icon: 'attr_onsite_management',
  },
  {
    title: 'Bike storage',
    icon: 'attr_bike_storage',
  },
  {
    title: 'Parking',
    icon: 'attr_parking',
  },
  {
    title: 'Lift',
    icon: 'attr_lift',
  },
  {
    title: 'CCTV',
    icon: 'attr_cctv',
  },
  {
    title: 'Controlled access',
    icon: 'attr_controlled_access',
  },
  {
    title: 'Gym',
    icon: 'attr_gym',
  },
  {
    title: 'Swimming pool',
    icon: 'attr_swimming_pool',
  },
  {
    title: 'Common area',
    icon: 'attr_common_area',
  },
  {
    title: 'Entertainment area',
    icon: 'attr_entertainment_area',
  },
  {
    title: 'Cinema',
    icon: 'attr_cinema',
  },
  {
    title: 'Co-working area',
    icon: 'attr_work_area',
  },
  {
    title: 'Free Wifi in Common Areas',
    icon: 'attr_wifi',
  },
  {
    title: 'Social events',
    icon: 'attr_social_events',
  },
  {
    title: 'Terrace',
    icon: 'attr_roof_terrace',
  },
  {
    title: 'Communal garden',
    icon: 'attr_communal_garden',
  },
  {
    title: 'Furnished',
    icon: 'attr_furnished',
    options: [
      { label: '', value: ' ' },
      { label: 'Furnished', value: 'Furnished' },
      { label: 'Flexible, furnished or unfurnished', value: 'Flexible' },
      { label: 'Unfurnished', value: 'Unfurnished' },
    ],
  },
  {
    title: 'Smoke alarm',
    icon: 'attr_smoke_alarm',
  },
  {
    title: 'Video door entry',
    icon: 'attr_video_door_entry',
  },
  {
    title: 'Heating',
    icon: 'attr_heating',
    options: [
      { label: '', value: ' ' },
      { label: 'Underfloor heating', value: 'Underfloor' },
      { label: 'Radiator heating', value: 'Radiator' },
    ],
  },
  {
    title: 'EPC rating ',
    icon: 'attr_epc',
    options: [
      { label: '', value: ' ' },
      { label: 'A', value: 'a' },
      { label: 'B', value: 'b' },
      { label: 'C', value: 'c' },
      { label: 'D', value: 'd' },
      { label: 'E', value: 'e' },
      { label: 'F', value: 'f' },
      { label: 'G', value: 'g' },
    ],
  },
];

export default attributesInputData;
