import * as React from 'react';
import { Box } from '@material-ui/core';
import { AttachFile, Image } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Snackbar, SnackbarType } from '../../../../../../../atoms';
import {
  InteractiveMediaVariant,
  MediaUploadArea,
} from '../../../../../../../molecules';

import useFlat from '../../../../../../../../api/developments/flats/useFlat';
import useFlatImages from '../../../../../../../../api/developments/flats/useFlatImages';
import {
  OrgFile,
  OrgFileCategory,
} from '../../../../../../../../api/orgs/types';
import useUpdateFlatImage from '../../../../../../../../api/developments/flats/useUpdateFlatImage';
import useIsScreenType from '../../../../../../../../utils/dom/useIsScreenType';

import { ScreenType } from '../../../../../../../../config';
import {
  ACCEPTABLE_FLAT_IMAGE_MIME_TYPES,
  MAX_FLAT_IMAGE_FILE_SIZE,
  MAX_FLAT_IMAGE_FILES_AT_ONCE,
  useSnackbar,
  useUploadFlatImages,
  UseUploadFlatImagesProps,
} from '../../../../../../../../hooks';

import {
  FormSection,
  MediaUploadAreaContainer,
  SnackbarContent,
} from '../../../components';
import FlatImageInteractiveMedia from '../../../components/FlatImageInteractiveMedia';
import BasicInformationPropertyForm from '../BasicInformationPropertyForm';
import { PropertyFormBaseProps } from '../types';
import useStyles from './PropertyForm.styles';

export interface PropertyFormProps extends PropertyFormBaseProps {
  flatId: number;
  orgId: number;
  developmentId: number;
  authToken: string;
}

const PropertyForm = ({
  authToken,
  orgId,
  developmentId,
  flatId,
  formControl,
  formSetValue,
  formSubmitHandler,
  formErrors,
}: PropertyFormProps) => {
  // Not actually 'mobile' screen type because we need things to be a bit wider
  // for the 2-column input fields layout.
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles();

  const interactiveMediaVariant = isMobile
    ? InteractiveMediaVariant.BEST_FIT_SQUARE
    : InteractiveMediaVariant.BEST_FIT_WIDE;

  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const commonApiProps = {
    authToken,
    orgId,
    developmentId,
    flatId,
  };

  const {
    data: flatResponse,
    isSuccess: flatLoaded,
    isLoading,
  } = useFlat(commonApiProps);

  const { data: flatImages } = useFlatImages(commonApiProps);

  const flatData = flatResponse?.data;
  const flatPrimaryMediaId = flatData?.flat_img_thumbnail_id;

  const flatImagesData = flatImages?.data.reduce<{
    flatImages: {
      count: number;
      primary: OrgFile | null;
      nonPrimaries: OrgFile[];
    };
    floorPlans: {
      count: number;
      primary: OrgFile | null;
      nonPrimaries: OrgFile[];
    };
  }>(
    (flatImagesData, flatImage) => {
      const { orgimages_id, category } = flatImage;
      const isPrimary =
        flatPrimaryMediaId && orgimages_id === flatPrimaryMediaId;

      const flatImagesDataCategory =
        category === OrgFileCategory.FLOOR_PLAN_IMAGE
          ? 'floorPlans'
          : 'flatImages';

      if (isPrimary) {
        flatImagesData[flatImagesDataCategory].primary = flatImage;
      } else {
        flatImagesData[flatImagesDataCategory].nonPrimaries.push(flatImage);
      }
      flatImagesData[flatImagesDataCategory].count += 1;

      return flatImagesData;
    },
    {
      flatImages: { count: 0, primary: null, nonPrimaries: [] },
      floorPlans: { count: 0, primary: null, nonPrimaries: [] },
    }
  );

  const commonUseUploadFlatImagesProps: Partial<UseUploadFlatImagesProps> = {
    onNewImagesError: (message) => {
      displaySnackbar(SnackbarType.ERROR, <SnackbarContent text={message} />);
    },
    onImageUploadSuccess: () => {
      displaySnackbar(
        SnackbarType.SUCCESS,
        <SnackbarContent text="Image uploaded successfully" />
      );
    },
    onImageUploadError: ({ file, message }) => {
      displaySnackbar(
        SnackbarType.ERROR,
        <SnackbarContent
          text={`Could not upload ${file.name}. Reason: ${message}`}
        />
      );
    },
  };

  const { stagingImages: stagingFlatImages, onNewImages: onNewFlatImages } =
    useUploadFlatImages({
      ...commonApiProps,
      ...commonUseUploadFlatImagesProps,
      defaultCategory: OrgFileCategory.FLAT_IMAGE,
    });

  const { stagingImages: stagingFloorPlans, onNewImages: onNewFloorPlans } =
    useUploadFlatImages({
      ...commonApiProps,
      ...commonUseUploadFlatImagesProps,
      defaultCategory: OrgFileCategory.FLOOR_PLAN_IMAGE,
    });

  const {
    mutate: updateFlatImage,
    isSuccess: isUpdateFlatImageSuccess,
    isLoading: isUpdateFlatImageLoading,
  } = useUpdateFlatImage({
    queryConfig: {
      onSuccess: () => {
        displaySnackbar(
          SnackbarType.SUCCESS,
          <SnackbarContent text="Image updated successfully" />
        );
      },
      onError: (error) => {
        displaySnackbar(
          SnackbarType.ERROR,
          <SnackbarContent
            text={`Failed to update image. Reason: ${error.message}`}
          />
        );
      },
    },
  });

  const mediaUploadAreaFlatImageItems: React.ReactNode[] = [];
  const mediaUploadAreaFloorPlanItems: React.ReactNode[] = [];

  const getInteractiveMediaCommonProps = ({
    orgimages_id,
    src_url,
    description,
    category,
  }: OrgFile) => ({
    ...commonApiProps,
    id: orgimages_id,
    variant: interactiveMediaVariant,
    src: src_url,
    description: description ?? '',
    category,
  });

  if (flatImagesData) {
    // Flat images

    if (flatImagesData.flatImages.primary) {
      const interactiveMediaCommonProps = getInteractiveMediaCommonProps(
        flatImagesData.flatImages.primary
      );

      mediaUploadAreaFlatImageItems.push(
        <FlatImageInteractiveMedia
          key={flatImagesData.flatImages.primary.orgimages_id}
          {...interactiveMediaCommonProps}
          isPrimary
          alt="flat image"
          status="idle"
          isUpdateSuccess={isUpdateFlatImageSuccess}
          isUpdateLoading={isUpdateFlatImageLoading}
          onSubmitButtonClick={({ category, description }) => {
            updateFlatImage({
              ...commonApiProps,
              category,
              description,
              orgImagesId: flatImagesData.flatImages.primary!.orgimages_id,
            });
          }}
        />
      );
    }

    mediaUploadAreaFlatImageItems.push(
      ...flatImagesData.flatImages.nonPrimaries.map((imageData) => {
        const interactiveMediaCommonProps =
          getInteractiveMediaCommonProps(imageData);

        return (
          <FlatImageInteractiveMedia
            key={imageData.orgimages_id}
            {...interactiveMediaCommonProps}
            alt="flat image"
            status="idle"
            isUpdateSuccess={isUpdateFlatImageSuccess}
            isUpdateLoading={isUpdateFlatImageLoading}
            onSubmitButtonClick={({ category, description }) => {
              updateFlatImage({
                ...commonApiProps,
                category,
                description,
                orgImagesId: imageData.orgimages_id,
              });
            }}
          />
        );
      })
    );

    // Floor plans

    if (flatImagesData.floorPlans.primary) {
      const interactiveMediaCommonProps = getInteractiveMediaCommonProps(
        flatImagesData.floorPlans.primary
      );

      mediaUploadAreaFloorPlanItems.push(
        <FlatImageInteractiveMedia
          key={flatImagesData.floorPlans.primary.orgimages_id}
          {...interactiveMediaCommonProps}
          isPrimary
          alt="floor plan"
          status="idle"
          isUpdateSuccess={isUpdateFlatImageSuccess}
          isUpdateLoading={isUpdateFlatImageLoading}
          onSubmitButtonClick={({ category, description }) => {
            updateFlatImage({
              ...commonApiProps,
              category,
              description,
              orgImagesId: flatImagesData.floorPlans.primary!.orgimages_id,
            });
          }}
        />
      );
    }

    mediaUploadAreaFloorPlanItems.push(
      ...flatImagesData.floorPlans.nonPrimaries.map((imageData) => {
        const interactiveMediaCommonProps =
          getInteractiveMediaCommonProps(imageData);

        return (
          <FlatImageInteractiveMedia
            key={imageData.orgimages_id}
            {...interactiveMediaCommonProps}
            alt="floor plan"
            status="idle"
            isUpdateSuccess={isUpdateFlatImageSuccess}
            isUpdateLoading={isUpdateFlatImageLoading}
            onSubmitButtonClick={({ category, description }) => {
              updateFlatImage({
                ...commonApiProps,
                category,
                description,
                orgImagesId: imageData.orgimages_id,
              });
            }}
          />
        );
      })
    );
  }

  Object.values(stagingFlatImages).forEach(({ id, preview, status }) => {
    if (status === 'uploading') {
      mediaUploadAreaFlatImageItems.push(
        <FlatImageInteractiveMedia
          key={id}
          {...commonApiProps}
          id={id}
          alt="flat image"
          status="loading"
          variant={interactiveMediaVariant}
          src={preview}
          description=""
          category={OrgFileCategory.FLAT_IMAGE}
        />
      );
    }
  });

  Object.values(stagingFloorPlans).forEach(({ id, preview, status }) => {
    if (status === 'uploading') {
      mediaUploadAreaFloorPlanItems.push(
        <FlatImageInteractiveMedia
          key={id}
          {...commonApiProps}
          id={id}
          alt="floor plan"
          status="loading"
          variant={interactiveMediaVariant}
          src={preview}
          description=""
          category={OrgFileCategory.FLOOR_PLAN_IMAGE}
        />
      );
    }
  });

  const uploadedFlatImagesCount = flatImagesData?.flatImages.count ?? 0;
  const uploadedFloorPlanCount = flatImagesData?.floorPlans.count ?? 0;

  return (
    <Box className={classes.formContainer}>
      {isLoading && (
        <Skeleton className={classes.skeleton} variant="rect" height={750} />
      )}
      {flatData && flatLoaded && (
        <>
          <FormSection title="Basic information">
            <BasicInformationPropertyForm
              flat={flatData}
              formControl={formControl}
              formSetValue={formSetValue}
              formSubmitHandler={formSubmitHandler}
              formErrors={formErrors}
            />
          </FormSection>

          <FormSection title="Flat images">
            <MediaUploadAreaContainer
              type="image"
              uploadButtonProps={{
                uploadAreaProps: { onDrop: onNewFlatImages },
              }}
            >
              <MediaUploadArea
                variant={
                  isMobile || uploadedFlatImagesCount > 0
                    ? 'simplified'
                    : 'full'
                }
                layout={isMobile ? 'equal' : 'cover'}
                icon={<Image fontSize="inherit" color="inherit" />}
                hintTitleFullVariant="Drag your photos here"
                hintSubtitleFullVariant="Add at least 5 photos"
                uploadAreaProps={{
                  onDrop: onNewFlatImages,
                  dropzoneOpts: {
                    accept: ACCEPTABLE_FLAT_IMAGE_MIME_TYPES,
                    maxSize: MAX_FLAT_IMAGE_FILE_SIZE,
                    maxFiles: MAX_FLAT_IMAGE_FILES_AT_ONCE,
                  },
                }}
                items={mediaUploadAreaFlatImageItems}
              />
            </MediaUploadAreaContainer>
          </FormSection>

          <FormSection title="Floor plans">
            <MediaUploadAreaContainer
              type="floorplan"
              uploadButtonProps={{
                uploadAreaProps: { onDrop: onNewFloorPlans },
              }}
            >
              <MediaUploadArea
                variant={
                  isMobile || uploadedFloorPlanCount > 0 ? 'simplified' : 'full'
                }
                layout={isMobile ? 'equal' : 'cover'}
                icon={<AttachFile fontSize="inherit" color="inherit" />}
                hintTitleFullVariant="Drag your floorplans here"
                hintSubtitleFullVariant="Add at least 1 floor plan"
                uploadAreaProps={{
                  onDrop: onNewFloorPlans,
                  dropzoneOpts: {
                    accept: ACCEPTABLE_FLAT_IMAGE_MIME_TYPES,
                    maxSize: MAX_FLAT_IMAGE_FILE_SIZE,
                    maxFiles: MAX_FLAT_IMAGE_FILES_AT_ONCE,
                  },
                }}
                items={mediaUploadAreaFloorPlanItems}
              />
            </MediaUploadAreaContainer>
          </FormSection>
        </>
      )}

      <Snackbar
        hideSnackbar={hideSnackbar}
        show={snackbarState.show}
        type={snackbarState.type}
      >
        <>{snackbarState.content}</>
      </Snackbar>
    </Box>
  );
};

export default React.memo(PropertyForm);
