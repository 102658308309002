import { LatLngLiteral } from '../api/googleMaps/gcpMaps/types';

export enum DevelopmentType {
  APARTMENTS = 'Apartments',
  HOUSES = 'Houses',
  HOUSES_AND_APARTMENTS = 'Houses/Apartments',
}

/**
 * Each enum entry's value correspond to the bill's ID in the database.
 */
export enum DevelopmentBill {
  WATER = 1,
  ELECTRICITY = 2,
  GAS = 3,
  TV_LICENSE = 4,
  INTERNET = 5,
  COUNCIL_TAX = 6,
  GYM = 7,
  DISNEY_PLUS = 8,
  NETFLIX = 9,
  ALL_BILLS_INCLUDED = 10,
  CLEANING = 11,
  SERVICES = 12,
}

export interface DevelopmentBuildingAttributes {
  attr_concierge?: boolean;
  attr_parcel?: boolean;
  attr_onsite_maintenance?: boolean;
  attr_bike_storage?: boolean;
  attr_parking?: boolean;
  attr_lift?: boolean;
  attr_cctv?: boolean;
  attr_controlled_access?: boolean;
  attr_gym?: boolean;
  attr_pool?: boolean;
  attr_common_area?: boolean;
  attr_entertainment_area?: boolean;
  attr_cinema?: boolean;
  attr_work_area?: boolean;
  attr_library?: boolean;
  attr_wifi?: boolean;
  attr_social_events?: boolean;
  attr_roof_terrace?: boolean;
  attr_communal_garden?: boolean;
  attr_furnished?: string;
  attr_smoke_alarm?: boolean;
  attr_video_door_entry?: boolean;
  attr_usb_port?: boolean;
  attr_epc?: 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g';
  attr_onsite_management?: boolean;
  attr_dining_room?: boolean;
  attr_bar?: boolean;
  attr_guest_suites?: boolean;
  attr_heating?: string;
  attr_hot_water?: string;
  attr_bathroom_type?: string;
  attr_swimming_pool?: boolean;
}

export interface DevelopmentFlatAttributes {
  fttr_bed_and_mattress?: boolean;
  fttr_bed_size?: string;
  fttr_built_in_wardrobe?: boolean;
  fttr_drawers?: boolean;
  fttr_sofa?: boolean;
  fttr_coffee_table?: boolean;
  fttr_dining_table_and_chairs: boolean;
  fttr_tv_stand?: boolean;
  fttr_tv?: boolean;
  fttr_open_plan_kitchen?: boolean;
  fttr_hob_type?: string;
  fttr_fridge?: boolean;
  fttr_oven?: boolean;
  fttr_microwave?: boolean;
  fttr_dishwasher?: boolean;
  fttr_washing_machine?: boolean;
  fttr_dryer?: boolean;
  fttr_smart_meter?: boolean;
  fttr_balcony?: boolean;
  fttr_pet_friendly?: boolean;
  fttr_student_friendly?: boolean;
  fttr_family_friendly?: boolean;
  fttr_armchair?: boolean;
}

export interface DevelopmentFeesAttributes {
  short_rent?: boolean | null;
  min_term?: string | null;
  max_term?: string | null;
  deposit_term?: string | null;
  notice_period?: string | null;
  zero_deposit?: boolean | null;
  break_clause?: string | null;
}

export interface Development
  extends DevelopmentBuildingAttributes,
    DevelopmentFlatAttributes {
  development_id: number;
  organization_id: number;

  // ----- General ----- //

  is_public: boolean;

  title: string;
  development_type?: string;
  description_free_text?: string;
  other_notes?: string;

  local_authority?: string;

  owner?: string;
  manager?: string;

  units_count?: number;
  units_prs_count?: number;

  development_website?: string;
  development_phone?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_email?: string;

  // ----- Construction ----- //

  completion_status?: 'Complete' | 'Incomplete';
  completion_date?: string | null;

  // ----- Location ----- //

  // This doesn't exist in the database but is injected by the API after
  // properties are found.
  // In the database, there is a "geography" field that is replaced by this
  // field by the API.
  // This latitude / longitude property is the deciding property of where the
  // property is. The other text-based properties are just for labelling
  // purposes.
  geographyJson: LatLngLiteral;

  address?: string;
  postcode?: string;
  city?: string;
  region?: string;
  area?: string;

  // ----- Images ----- //

  // The thumbnail is the primary image
  img_thumbnail_id?: string;
}
