import { ConversionVariant } from './types';

function formValueToApiValue(
  value: string,
  conversionVariant: 'string'
): string | null;
function formValueToApiValue(
  value: string,
  conversionVariant: 'boolean'
): boolean | null;
function formValueToApiValue(
  value: string,
  conversionVariant: 'number'
): number | null;
function formValueToApiValue(
  value: string,
  conversionVariant: ConversionVariant
): string | boolean | number | null {
  switch (conversionVariant) {
    case 'number':
      return value === '' ? null : Number(value);
    case 'boolean':
      return value === '' ? null : !!value;
    case 'string':
    default:
      return value;
  }
}

export default formValueToApiValue;
