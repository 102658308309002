import { SelectWithTitleAndIconProps } from '../../../../../../molecules';

const attributesInputData: Pick<
  SelectWithTitleAndIconProps,
  'title' | 'icon' | 'options'
>[] = [
  {
    title: 'Bed size',
    icon: 'fttr_bed_size',
    options: [
      { label: ' ', value: '' },
      { label: 'Single', value: 'single' },
      { label: 'Double', value: 'double' },
      { label: 'Flexible', value: 'flexible' },
      { label: 'King', value: 'king' },
    ],
  },
  {
    title: 'Wardrobe',
    icon: 'fttr_built_in_wardrobe',
  },
  {
    title: 'Drawers',
    icon: 'fttr_drawers',
  },
  {
    title: 'Sofa',
    icon: 'fttr_sofa',
  },
  {
    title: 'Coffee table',
    icon: 'fttr_coffee_table',
  },
  {
    title: 'Dining table and chairs',
    icon: 'fttr_dining_table_and_chairs',
  },
  {
    title: 'TV',
    icon: 'fttr_tv',
  },
  {
    title: 'Open-plan kitchen',
    icon: 'fttr_open_plan_kitchen',
  },
  {
    title: 'Hob type',
    icon: 'fttr_hob_type',
    options: [
      { label: ' ', value: '' },
      { label: 'Electric hob', value: 'Electric Hob' },
      { label: 'Gas hob', value: 'Gas Hob' },
      { label: 'Induction hob', value: 'Induction Hob' },
    ],
  },
  {
    title: 'Fridge',
    icon: 'fttr_fridge',
  },
  {
    title: 'Oven',
    icon: 'fttr_oven',
  },
  {
    title: 'Microwave',
    icon: 'fttr_microwave',
  },
  {
    title: 'Dishwasher',
    icon: 'fttr_dishwasher',
  },
  {
    title: 'Washing machine',
    icon: 'fttr_washing_machine',
  },
  {
    title: 'Dryer',
    icon: 'fttr_dryer',
  },
  {
    title: 'Smart meter',
    icon: 'fttr_smart_meter',
  },
  {
    title: 'Balcony',
    icon: 'fttr_balcony',
  },
  {
    title: 'Short rent ',
    icon: 'short_rent',
  },
  {
    title: 'Pet friendly',
    icon: 'fttr_pet_friendly',
  },
  {
    title: 'Student friendly',
    icon: 'fttr_student_friendly',
  },
  {
    title: 'Family friendly',
    icon: 'fttr_family_friendly',
  },
  {
    title: 'Bathroom type',
    icon: 'attr_bathroom_type',
    options: [
      { label: ' ', value: '' },
      { label: 'Bathtub & shower', value: 'Bathtub & shower' },
      { label: 'Shower only', value: 'Shower only' },
    ],
  },
];

export default attributesInputData;
