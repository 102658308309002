import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { DevelopmentV2 } from '../property/types';

export interface UpdateDevelopmentProps {
  authToken: string;
  orgId: number;
  developmentId: number;
  updater: Partial<DevelopmentV2>;
}

export interface UpdateDevelopmentSuccessResponse {
  data: DevelopmentV2;
}

export type UpdateDevelopmentMutateOptions = MutateOptions<
  UpdateDevelopmentSuccessResponse,
  Error,
  UpdateDevelopmentProps
>;

export async function updateDevelopment({
  authToken,
  orgId,
  developmentId,
  updater,
}: UpdateDevelopmentProps): Promise<UpdateDevelopmentSuccessResponse> {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'BTR-Organization': `${orgId}`,
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const res = await fetch(
    `${process.env.GATSBY_API_HOST}/api/development/${developmentId}`,
    {
      method: 'PATCH',
      headers,
      credentials: 'include',
      body: JSON.stringify(updater),
    }
  );

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return res.json() as Promise<UpdateDevelopmentSuccessResponse>;
}

export default function useUpdateDevelopment() {
  const queryClient = useQueryClient();

  return useMutation<
    UpdateDevelopmentSuccessResponse,
    Error,
    UpdateDevelopmentProps
  >(updateDevelopment, {
    onSuccess: (data, variables) => {
      void queryClient.setQueryData(
        [
          `${process.env.GATSBY_API_HOST}/api/development/${variables.developmentId}`,
          { authToken: variables.authToken, orgId: variables.orgId },
        ],
        { data: data.data }
      );
    },
  });
}
