import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    pageCtn: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.gray.light2,
    },
    formControllersCtn: ({ isMobile }) =>
      isMobile
        ? {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2.25)}px`,
          }
        : {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: `${theme.spacing(2.25)}px ${theme.spacing(2.5)}px`,
          },
  })
);

export default useStyles;
