import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Skeleton } from '@material-ui/lab';
import { Link, Typography } from '../../../../atoms';
import { AccountLayout } from '../../../../layout';
import Layout from '../../../../layout/CommonLayout/Layout';
import GenericPage from '../../../../layout/CommonLayout/GenericPage';

import SEO from '../../../../atoms/SEO';
import MyAccountSideBar from '../../MyAccountSideBar/MyAccountSideBar';

import useAuthToken from '../../../../../store/auth/hooks/useAuthToken';
import useDevelopment from '../../../../../api/developments/useDevelopment';
import useMyUserDetails from '../../../../../api/auth/me/useMyUserDetails';
import { useSignOut } from '../../../../../hooks';

export type DevelopmentEditPageProps = RouteComponentProps<{
  orgId: string;
  developmentId: string;
}> & { children: React.ReactNode };

export default function DevelopmentEditPage({
  orgId,
  developmentId,
  children,
}: DevelopmentEditPageProps) {
  const normalizedOrgId = orgId ? Number(orgId) : 0;
  const normalizedDevelopmentId = developmentId ? Number(developmentId) : 0;

  const authToken = useAuthToken();
  const signOut = useSignOut();

  const { data: myUserDetails } = useMyUserDetails({
    authToken,
  });

  const { data: developmentResponse, isLoading: isLoadingDevelopment } =
    useDevelopment({
      authToken,
      orgId: normalizedOrgId,
      developmentId: normalizedDevelopmentId,
    });

  const pageTitle = developmentResponse
    ? developmentResponse.data.title
    : 'Loading';

  const leftHandSideContent = (
    <MyAccountSideBar
      userData={myUserDetails?.user}
      developmentData={developmentResponse?.data}
      onSignOutButtonClick={() => signOut()}
    />
  );

  const rightHandSideBreadcrumbsContent = [
    <Link key="all developments" to="/my-account/properties" noColor>
      <Typography
        color="gray"
        colorVariant="dark"
        fontSize={20}
        component="span"
      >
        All developments
      </Typography>
    </Link>,
    <Typography
      key="development title"
      color="yellow"
      colorVariant="contrastText"
      fontSize={20}
      component="span"
    >
      {isLoadingDevelopment ? (
        <Skeleton variant="text" width={200} animation="wave" />
      ) : (
        developmentResponse?.data.title
      )}
    </Typography>,
  ];

  return (
    <Layout>
      <SEO title={pageTitle} />
      <GenericPage>
        <AccountLayout
          leftHandSide={leftHandSideContent}
          rightHandSide={children}
          breadcrumbsContent={rightHandSideBreadcrumbsContent}
        />
      </GenericPage>
    </Layout>
  );
}
