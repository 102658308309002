import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    pageCtn: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.gray.light2,
    },
  })
);

export default useStyles;
