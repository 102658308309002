import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { Snackbar, SnackbarType, Spacer } from '../../../../../../atoms';
import { EditPropertyFormLayout } from '../../../../../../layout';

import useDevelopment from '../../../../../../../api/developments/useDevelopment';
import useAuthToken from '../../../../../../../store/auth/hooks/useAuthToken';
import useUpdateDevelopment from '../../../../../../../api/developments/useUpdateDevelopment';
import useUpdateDevelopmentDetails from '../../../../../../../api/property/useUpdateDevelopmentDetails';
import useDevelopmentDetails from '../../../../../../../api/developments/useDevelopmentDetails';
import useAutocompleteServices from '../../../../../../../api/googleMaps/gcpPlaces/hooks/useAutocompleteServices';

import {
  useAddressAutocomplete,
  useSnackbar,
} from '../../../../../../../hooks';
import { DevelopmentType } from '../../../../../../../models/developments';
import useIsScreenType from '../../../../../../../utils/dom/useIsScreenType';
import { APP_ROUTES } from '../../../../../../../config/app';
import { ScreenType } from '../../../../../../../config';

import { SnackbarContent } from '../../components';
import parseFormData from './utils/parseFormData';
import MobileTabsNavigator from './MobileTabsNavigator/MobileTabsNavigator';
import useStyles from './BasicInformationSubPage.styles';
import { BasicInformationFormFieldValues, BasicInformationTab } from './types';
import { BasicInformationForm } from './BasicInformationForm';
import { LocationForm } from './LocationForm';
import DevelopmentContactFormMobile from './DevelopmentContactForm';
import useOrgLandlords from '../../../../../../../api/orgs/useOrgLandlords';
import { completionDateStringToYear } from './utils/parseCompletionDate';

export default function BasicInformationSubPage({
  developmentId,
  orgId,
}: RouteComponentProps<{ developmentId: string; orgId: string }>) {
  const normalizedOrgId = orgId ? Number(orgId) : 0;
  const normalizedDevelopmentId = developmentId ? Number(developmentId) : 0;

  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);

  const classes = useStyles({ isMobile });

  const [currentTab, setCurrentTab] = React.useState(
    BasicInformationTab.BASIC_INFORMATION
  );

  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const authToken = useAuthToken();
  const useDataCommonProps = {
    authToken,
    orgId: normalizedOrgId,
    developmentId: normalizedDevelopmentId,
  };

  const { data: development, status: developmentStatus } =
    useDevelopment(useDataCommonProps);
  const { data: developmentDetails, status: developmentDetailsStatus } =
    useDevelopmentDetails(useDataCommonProps);
  const { data: orgLandlords } = useOrgLandlords({
    authToken,
    orgId: normalizedOrgId,
  });

  const isPageDataLoading =
    developmentStatus === 'loading' || developmentDetailsStatus === 'loading';

  const basicInformationFormDefaultValues:
    | BasicInformationFormFieldValues
    | undefined =
    development && developmentDetails
      ? {
          title: development.data.title,
          description_free_text: development.data.description_free_text ?? '',
          developments_details_content:
            developmentDetails.data.developments_details_content ?? '',
          development_type:
            (development.data.development_type as DevelopmentType) ?? '',
          units_count: `${development.data.units_count}`,
          manager_id: development.data.manager_id ?? 0,
          virtual_tour_url: development.data.virtual_tour_url ?? '',
          development_website: development.data.development_website ?? '',
          address: development.data.address ?? '',
          postcode: development.data.postcode ?? '',
          city: development.data.city ?? '',
          area: development.data.area ?? '',
          region: development.data.region ?? '',
          local_authority: development.data.local_authority ?? '',
          country: development.data.country ?? '',
          geometry: development.data.geographyJson,
          contact_name: development.data.contact_name ?? '',
          contact_email: development.data.contact_email ?? '',
          contact_phone: development.data.contact_phone ?? '',
          contact_job_title: development.data.contact_job_title ?? '',
          completion_date: completionDateStringToYear(
            `${development.data.completion_date}` ?? ''
          ),
        }
      : undefined;

  const { mutate: updateDevelopment, status: updateDevelopmentStatus } =
    useUpdateDevelopment();
  const {
    mutate: updateDevelopmentDetails,
    status: updateDevelopmentDetailsStatus,
  } = useUpdateDevelopmentDetails();

  const formSubmitHandlerOnSuccess = () => {
    displaySnackbar(
      SnackbarType.SUCCESS,
      <SnackbarContent text="Your information has been successfully saved" />
    );
  };
  const formSubmitHandlerOnError = (error) => {
    displaySnackbar(
      SnackbarType.ERROR,
      <SnackbarContent
        text={`Failed to update development. Reason: ${
          (error as Error).message
        }`}
      />
    );
  };

  const formSubmitHandler: SubmitHandler<BasicInformationFormFieldValues> = (
    data
  ) => {
    const { developmentUpdater, developmentDetailsUpdater } =
      parseFormData(data);

    updateDevelopment(
      {
        ...useDataCommonProps,
        updater: developmentUpdater,
      },
      {
        onSuccess: formSubmitHandlerOnSuccess,
        onError: formSubmitHandlerOnError,
      }
    );

    updateDevelopmentDetails(
      {
        ...useDataCommonProps,
        updater: developmentDetailsUpdater,
      },
      {
        onSuccess: formSubmitHandlerOnSuccess,
        onError: formSubmitHandlerOnError,
      }
    );
  };

  const {
    control: basicInformationFormControl,
    handleSubmit: basicInformationFormSubmitFactory,
    getValues: basicInformationFormGetValues,
    setValue: basicInformationFormSetValue,
    resetField: basicInformationFormResetField,
    formState: { errors: formErrors },
    watch: basicInformationFormWatch,
  } = useForm<BasicInformationFormFieldValues>();

  const basicInformationFormSubmit =
    basicInformationFormSubmitFactory(formSubmitHandler);

  const basicInformationFormSubmitIsLoading =
    updateDevelopmentStatus === 'loading' ||
    updateDevelopmentDetailsStatus === 'loading';

  const mapRef = React.createRef();
  const mapsRef = React.createRef();
  const { autocompleteService, placesService } = useAutocompleteServices({
    googleMapsAttributeElementId: 'google-maps-att-el',
  });
  const {
    setInputValue,
    predictions: autocompletePredictions,
    selectedAutocompletePrediction,
    setSelectedAutocompletePrediction: selectAutocompletePrediction,
    getPlaceDetails,
    placeDetails,
  } = useAddressAutocomplete({
    autocompleteService,
    placesService,
  });

  React.useEffect(() => {
    if (selectedAutocompletePrediction?.place_id) {
      getPlaceDetails(selectedAutocompletePrediction.place_id);
    }
  }, [selectedAutocompletePrediction?.place_id]);

  const linkToEditPropertyRoot =
    APP_ROUTES.propertyManagementEditDevelopmentRoot({
      orgId: normalizedOrgId,
      developmentId: normalizedDevelopmentId,
    });

  const commonFormProps = {
    defaultValues: basicInformationFormDefaultValues,
    formControl: basicInformationFormControl,
    formSubmit: basicInformationFormSubmit,
    formSubmitIsLoading: basicInformationFormSubmitIsLoading,
  };

  const shouldShowBasicInformationForm =
    !isMobile ||
    (isMobile && currentTab === BasicInformationTab.BASIC_INFORMATION);
  const shouldShowLocationForm =
    !isMobile || (isMobile && currentTab === BasicInformationTab.LOCATION);
  const shouldShowDevelopmentContactForm =
    !isMobile || (isMobile && currentTab === BasicInformationTab.CONTACT);

  const basicInformationFormPostcode = basicInformationFormWatch(
    'postcode',
    (development && development.data.postcode) ?? ''
  );

  // @ts-ignore
  return (
    <Box className={classes.pageCtn}>
      <EditPropertyFormLayout
        title="BASIC INFORMATION"
        mainPageUrl={linkToEditPropertyRoot}
        formIsLoading={isPageDataLoading}
        formSubmitHandler={basicInformationFormSubmit}
        formSubmitIsLoading={basicInformationFormSubmitIsLoading}
      >
        {isMobile && (
          <>
            <MobileTabsNavigator
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
            <Spacer y={2} />
          </>
        )}

        {shouldShowBasicInformationForm && (
          // TODO
          // @ts-ignore
          <BasicInformationForm
            {...commonFormProps}
            formErrors={formErrors}
            orgLandlords={orgLandlords ?? []}
          />
        )}

        {shouldShowLocationForm && (
          // TODO
          // @ts-ignore
          <LocationForm
            {...commonFormProps}
            postcode={basicInformationFormPostcode}
            setAreaRegion={({ area, region }) => {
              basicInformationFormSetValue('area', area);
              basicInformationFormSetValue('region', region);
            }}
            setCity={(city) => basicInformationFormSetValue('city', city)}
            setLocalAuthority={(localAuthority) =>
              basicInformationFormSetValue('local_authority', localAuthority)
            }
            geometry={
              basicInformationFormGetValues('geometry') ??
              basicInformationFormDefaultValues?.geometry
            }
            setGeometry={(geometry) => {
              // Should call both resetField() and setValue() otherwise the
              // form won't re-render and the map section won't update as
              // expected.
              basicInformationFormResetField('geometry');
              basicInformationFormSetValue('geometry', geometry);
            }}
            selectedPlaceDetails={placeDetails}
            setAddressTexts={({ address, postcode, city, country }) => {
              basicInformationFormSetValue('address', address);
              basicInformationFormSetValue('postcode', postcode);
              basicInformationFormSetValue('city', city);
              basicInformationFormSetValue('country', country);
            }}
            mapRef={mapRef}
            mapsRef={mapsRef}
            addressAutocompleteProps={{
              setInputValue,
              autocompletePredictions,
              selectedAutocompletePrediction,
              selectAutocompletePrediction,
            }}
          />
        )}

        {shouldShowDevelopmentContactForm && (
          // TODO
          // @ts-ignore
          <DevelopmentContactFormMobile {...commonFormProps} />
        )}
      </EditPropertyFormLayout>

      <Snackbar
        hideSnackbar={hideSnackbar}
        show={snackbarState.show}
        type={snackbarState.type}
      >
        <>{snackbarState.content}</>
      </Snackbar>
    </Box>
  );
}
