import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme) =>
  createStyles({
    pageCtn: {
      backgroundColor: '#F3F3F3',
      padding: theme.spacing(3, 0),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0),
      },
    },
  })
);

export default useStyles;
