import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme) =>
  createStyles({
    formCtn: ({ isMobile }) =>
      isMobile
        ? {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.common.white,
            padding: theme.spacing(2.5, 2),
          }
        : {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2.5, 0),
          },

    formFieldsCtn: {
      display: 'flex',
      flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
      gap: theme.spacing(2.25),
    },

    formFields: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.25),
      flex: 1,
    },
    map: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.25),
      flex: 1,
    },

    note: {
      fontStyle: 'italic',
      color: theme.palette.grey['600'],
    },
  })
);

export default useStyles;
