export interface IsStringAllDigitsConfig {
  allowPeriod?: boolean;
}

/**
 * Return true if the string contains only digits.
 */
export default function isStringAllDigits(
  str: string,
  { allowPeriod }: IsStringAllDigitsConfig = {}
): boolean {
  const REGEX = allowPeriod ? /^[\d.-]+$/ : /^\d+$/;

  return REGEX.test(str);
}
