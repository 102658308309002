import { DevelopmentType } from '../../../../../../../models/developments';
import { Control, FieldErrors } from 'react-hook-form';
import * as React from 'react';
import { LatLngCoords } from '../../../../../../../utils/geolocation';

export interface BaseFormProps<FormFieldValues> {
  /**
   * Field values per the API. This is used as the default values.
   */
  defaultValues: FormFieldValues | undefined;

  formControl: Control<FormFieldValues>;
  formSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  formSubmitIsLoading: boolean;
  formErrors?: FieldErrors<FormFieldValues>;
}

export enum BasicInformationTab {
  BASIC_INFORMATION = 'Basic Information',
  LOCATION = 'Location',
  CONTACT = 'Contact',
}

export interface BasicInformationFieldValues {
  title: string;
  description_free_text: string; // Development short description (short text)
  developments_details_content: string; // Development description (long Markdown text)
  development_type: DevelopmentType | '';
  units_count: string;
  manager_id: number;
  virtual_tour_url: string;
  development_website: string;

  // In the database, this is a Date field. In the front-end, we only allow
  // editing the year, thus it is a year-string field. When we provide this to
  // the API, we need to convert it to a date string of YYYY-01-01. When this
  // data is received from the API, it's a full date string e.g. YYYY-01-11T00:00:00.000Z.
  completion_date: string;
}

export interface LocationFieldValues {
  address: string;
  postcode: string;
  country: string;
  geometry: LatLngCoords;

  // These fields are automatically inferred. Users can't edit them.
  city: string;
  area: string;
  region: string;
  local_authority: string;
}

export interface DevelopmentContactFieldValues {
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  contact_job_title: string;
}

export type BasicInformationFormFieldValues = BasicInformationFieldValues &
  LocationFieldValues &
  DevelopmentContactFieldValues;
