import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import { TextField } from '../../../../../../../atoms';
import { FlatInDb } from '../../../../../../../../api/property/types';
import useStyles from './BasicInformationPropertyForm.styles';
import { PropertyFormBaseProps } from '../types';
import { isStringAllDigits } from '../../../../../../../../utils/validators';

export interface BasicInformationFormProps extends PropertyFormBaseProps {
  flat: FlatInDb;
}

export default function BasicInformationPropertyForm({
  flat,
  formControl,
  formSubmitHandler,
  formErrors,
}: BasicInformationFormProps) {
  const classes = useStyles();

  const flatId = flat.flat_id;
  const rentPcmFormId = `${flatId}.rent_pcm`;

  return (
    <form onSubmit={formSubmitHandler}>
      <Box className={classes.formContainer}>
        <Grid container className={classes.gridContainer}>
          <Grid
            item
            lg={6}
            xs={12}
            className={clsx(classes.gridItemLeft, classes.gridItem)}
          >
            <Controller
              name={`${flatId}.flat_title`}
              defaultValue={flat.flat_title}
              control={formControl}
              render={({ field }) => (
                <TextField label="Title" fullWidth {...field} />
              )}
            />
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            className={clsx(classes.gridItemRight, classes.gridItem)}
          >
            <Controller
              name={`${flatId}.rent_pcm`}
              control={formControl}
              // TODO
              // @ts-ignore
              defaultValue={flat?.rent_pcm ?? ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rent pcm"
                  fullWidth
                  error={!!formErrors?.[flatId]?.rent_pcm}
                  helperText={formErrors?.[flatId]?.rent_pcm?.message}
                />
              )}
              rules={{
                validate: {
                  digitsOnly: (value) =>
                    `${value}` !== '' &&
                    value !== undefined &&
                    !isStringAllDigits(`${value}`, { allowPeriod: true })
                      ? 'Only digits are allowed'
                      : true,
                  rentWithinRange: (value) =>
                    `${value}` !== '' &&
                    value !== undefined &&
                    value !== null &&
                    value < 0
                      ? 'Rent must not be negative. Note: zero-value rent properties are not visible'
                      : true,
                },
              }}
            />
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
            className={clsx(classes.gridItemLeft, classes.gridItem)}
          >
            <Controller
              name={`${flat.flat_id}.flat_size`}
              control={formControl}
              defaultValue={flat?.flat_size ?? 0}
              render={({ field }) => (
                <TextField label="Size (sq ft)" fullWidth {...field} />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
