import * as React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import useStyles from './MobileNavigationSubPage.styles';
import { Link, Typography } from '../../../../../../atoms';

import { APP_ROUTES } from '../../../../../../../config/app';
import { RouteComponentProps } from '@reach/router';
import useIsScreenType from '../../../../../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../../../../../config';

function HeaderItem({ text, to }: { text: string; to: string }) {
  const classes = useStyles();

  return (
    <Link to={to}>
      <Box className={clsx(classes.item, classes.headerItem)}>
        <Typography color="gray" colorVariant="dark" fontWeight={600}>
          {text}
        </Typography>
      </Box>
    </Link>
  );
}

function SubItem({ text, to }: { text: string; to: string }) {
  const classes = useStyles();

  return (
    <Link to={to}>
      <Box className={clsx(classes.item, classes.subItem)}>
        <Typography color="gray" colorVariant="contrastText" fontWeight={600}>
          {text}
        </Typography>
      </Box>
    </Link>
  );
}

export default function MobileNavigationSubPage({
  orgId,
  developmentId,
}: RouteComponentProps<{ developmentId: string; orgId: string }>) {
  const normalizedOrgId = Number(orgId) ?? 0;
  const normalizedDevelopmentId = Number(developmentId) ?? 0;

  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles();

  const commonAppRouteProps = {
    orgId: normalizedOrgId,
    developmentId: normalizedDevelopmentId,
  };

  if (!isMobile) return null;

  return (
    <Box className={classes.mobileNavigationSubPageCtn}>
      <HeaderItem
        text="PROPERTY INFORMATION"
        to={APP_ROUTES.propertyManagementEditDevelopmentRoot(
          commonAppRouteProps
        )}
      />

      <Box className={classes.subItemsCtn}>
        <SubItem
          text="Basic information"
          to={APP_ROUTES.propertyManagementEditDevelopmentBasicInformation(
            commonAppRouteProps
          )}
        />
        <SubItem
          text="Tenancy terms"
          to={APP_ROUTES.propertyManagementEditDevelopmentTenancyTerms(
            commonAppRouteProps
          )}
        />
        <SubItem
          text="Facilities"
          to={APP_ROUTES.propertyManagementEditDevelopmentFacilities(
            commonAppRouteProps
          )}
        />
        <SubItem
          text="Amenities"
          to={APP_ROUTES.propertyManagementEditDevelopmentAmenities(
            commonAppRouteProps
          )}
        />
        <SubItem
          text="Development media"
          to={APP_ROUTES.propertyManagementEditDevelopmentMedias(
            commonAppRouteProps
          )}
        />
        <SubItem
          text="Development properties"
          to={APP_ROUTES.propertyManagementEditDevelopmentProperties(
            commonAppRouteProps
          )}
        />
        <SubItem
          text="Public listing"
          to={APP_ROUTES.propertyManagementEditDevelopmentPublicListing(
            commonAppRouteProps
          )}
        />
      </Box>
    </Box>
  );
}
