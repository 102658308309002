import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    tabButton: {
      minWidth: `${theme.spacing(9)}px`,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(0.75)}px`,

      backgroundColor: theme.palette.common.white,
      color: theme.palette.gray.contrastText,

      border: 'none',
      borderRadius: '4px',

      '&.selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },

    tabButtonText: {
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 600,
    },
  })
);

export default useStyles;
