import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { DevelopmentActivityInDb, DevelopmentDetails } from './types';

export interface UpdateDevelopmentDetailsProps {
  authToken: string;
  orgId: number;
  developmentId: number;
  updater: Partial<DevelopmentDetails>;
}

export interface UpdateDevelopmentDetailsResponse {
  data: {
    developments_details_id: number;
    development_id: number;
    developments_details_content: string | null;
  };
  meta: {
    activity: DevelopmentActivityInDb;
  };
}

export type UpdateDevelopmentDetailsMutateOptions = MutateOptions<
  UpdateDevelopmentDetailsResponse,
  Error,
  UpdateDevelopmentDetailsProps
>;

export async function updateDevelopmentDetails({
  authToken,
  orgId,
  developmentId,
  updater,
}: UpdateDevelopmentDetailsProps): Promise<UpdateDevelopmentDetailsResponse> {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'BTR-Organization': `${orgId}`,
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const res = await fetch(
    `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/developmentDetails`,
    {
      method: 'PUT',
      headers,
      credentials: 'include',
      body: JSON.stringify(updater),
    }
  );

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return res.json() as Promise<UpdateDevelopmentDetailsResponse>;
}

export default function useUpdateDevelopmentDetails() {
  const queryClient = useQueryClient();

  return useMutation<
    UpdateDevelopmentDetailsResponse,
    Error,
    UpdateDevelopmentDetailsProps
  >(updateDevelopmentDetails, {
    onSuccess: (data, variables) => {
      void queryClient.setQueryData(
        [
          `${process.env.GATSBY_API_HOST}/api/development/${variables.developmentId}/developmentDetails`,
          { authToken: variables.authToken, orgId: variables.orgId },
        ],
        { data: data.data }
      );
    },
  });
}
