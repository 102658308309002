import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    formContainer: {
      backgroundColor: '#fff',
      marginBottom: theme.spacing(2),
    },
    formTitle: {
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(3.25),
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.up('md')]: {
        fontWeight: theme.typography.fontWeightSemiBold,
        fontSize: theme.typography.pxToRem(20),
      },
    },
    center: {
      textAlign: 'center',
    },
    gridItem: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginBottom: 0,
      },
    },

    gridItemLeft: {
      paddingRight: 42.5,
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
      },
    },

    gridItemRight: {
      paddingLeft: 42.5,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
    },
  })
);

export default useStyles;
