import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { OrgFile, OrgFileCategory } from '../orgs/types';
import { DevelopmentActivityInDb } from '../property/types';

interface FetcherProps {
  authToken: string;
  orgImagesId: string;
  developmentId: number;
  category: OrgFileCategory;
  description: string;
  orgId: number;
}

interface FetcherResponse {
  data: {
    databaseResult: {
      newDevelopmentImages: OrgFile[];
      insertedImage: OrgFile;
    };
  };

  meta: {
    activity: DevelopmentActivityInDb;
    imageId: string;
  };
}

export async function updateDevelopmentImage({
  authToken,
  orgImagesId,
  developmentId,
  category,
  description,
  orgId,
}: FetcherProps): Promise<FetcherResponse> {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'BTR-Organization': `${orgId}`,
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const res = await fetch(
    `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/image/${orgImagesId}`,
    {
      method: 'PUT',
      headers,
      credentials: 'include',
      body: JSON.stringify({
        category,
        description,
      }),
    }
  );

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return res.json() as Promise<FetcherResponse>;
}

export interface UseUpdateDevelopmentImageProps {
  queryConfig?: UseMutationOptions<FetcherResponse, Error, FetcherProps>;
}

export default function useUpdateDevelopmentImage({
  queryConfig = {},
}: UseUpdateDevelopmentImageProps = {}) {
  const queryClient = useQueryClient();

  const { onSuccess: additionalOnSuccess, ...restQueryConfig } = queryConfig;

  return useMutation<FetcherResponse, Error, FetcherProps>(
    updateDevelopmentImage,
    {
      onSuccess: (data, variables, context) => {
        void queryClient.setQueryData(
          [
            `${process.env.GATSBY_API_HOST}/api/development/${variables.developmentId}/images`,
            { authToken: variables.authToken, orgId: variables.orgId },
          ],
          { data: data.data.databaseResult.newDevelopmentImages }
        );

        if (additionalOnSuccess) {
          void additionalOnSuccess(data, variables, context);
        }
      },

      ...restQueryConfig,
    }
  );
}
