import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { DevelopmentImage } from '../property/types';
import pvtFetcher from '../utils/pvtFetcher';

interface FetcherResponse {
  data: DevelopmentImage[];
}

export default function useDevelopmentImages({
  authToken,
  orgId,
  developmentId,
  queryConfig,
}: {
  authToken: string;
  orgId: number;
  developmentId: number;
  queryConfig?: UseQueryOptions<FetcherResponse, Error>;
}) {
  const url = `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/images`;

  return useQuery<FetcherResponse, Error>(
    [url, { authToken, orgId }],
    () => pvtFetcher(url, { authToken, orgId }),
    queryConfig
  );
}
