import * as React from 'react';
import {
  InteractiveMedia,
  InteractiveMediaImageProps,
  InteractiveMediaType,
} from '../../../../../../molecules';

import useDeleteFlatImage from '../../../../../../../api/developments/flats/useDeleteFlatImage';

import useIsScreenType from '../../../../../../../utils/dom/useIsScreenType';
import { ScreenType } from '../../../../../../../config';
import EditInteractiveMediaCard, {
  EditInteractiveMediaCardProps,
} from '../EditInteractiveMediaCard/EditInteractiveMediaCard';
import useUpdateFlatImageThumbnail from '../../../../../../../api/developments/flats/useUpdateFlatImageThumbnail';

export interface FlatImageInteractiveMediaProps
  extends Pick<
      EditInteractiveMediaCardProps,
      | 'description'
      | 'category'
      | 'size'
      | 'creationDate'
      | 'dimension'
      | 'extension'
      | 'onSubmitButtonClick'
      | 'isUpdateSuccess'
      | 'isUpdateLoading'
    >,
    Omit<
      InteractiveMediaImageProps,
      | 'type'
      | 'overlaySize'
      | 'canDelete'
      | 'onDeleteButtonClick'
      | 'canMakePrimary'
      | 'onMakePrimaryButtonClick'
      | 'canEdit'
      | 'onEditButtonClick'
      | 'canAdd'
      | 'onAddButtonClick'
    > {
  authToken: string;
  developmentId: number;
  orgId: number;
  flatId: number;
}

export default function FlatImageInteractiveMedia({
  authToken,
  developmentId,
  orgId,
  description,
  category,
  size,
  creationDate,
  dimension,
  extension,
  isUpdateSuccess,
  isUpdateLoading,
  onSubmitButtonClick,
  flatId,
  ...props
}: FlatImageInteractiveMediaProps) {
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);

  const [editInteractiveMediaCardOpen, setEditInteractiveMediaCardOpen] =
    React.useState(false);

  const { mutate: deleteFlatImage, status: deleteFlatImageStatus } =
    useDeleteFlatImage();

  const {
    mutate: updateFlatImageThumbnail,
    status: updateFlatImageThumbnailStatus,
  } = useUpdateFlatImageThumbnail();

  const commonApiProps = {
    authToken,
    developmentId,
    orgId,
    flatId,
    imageId: props.id,
  };

  if (deleteFlatImageStatus === 'success') return null;

  return (
    <>
      <InteractiveMedia
        type={InteractiveMediaType.IMAGE}
        overlaySize={isMobile ? 'small' : 'large'}
        canDelete={deleteFlatImageStatus !== 'loading'}
        onDeleteButtonClick={
          props.status !== 'loading'
            ? () => {
                deleteFlatImage(commonApiProps);
              }
            : undefined
        }
        canEdit={deleteFlatImageStatus !== 'loading'}
        onEditButtonClick={
          props.status !== 'loading'
            ? () => setEditInteractiveMediaCardOpen(true)
            : undefined
        }
        canMakePrimary={updateFlatImageThumbnailStatus !== 'loading'}
        onMakePrimaryButtonClick={
          props.status !== 'loading'
            ? () => {
                updateFlatImageThumbnail(commonApiProps);
              }
            : undefined
        }
        {...props}
      />

      <EditInteractiveMediaCard
        open={editInteractiveMediaCardOpen}
        onClose={() => setEditInteractiveMediaCardOpen(false)}
        type={InteractiveMediaType.IMAGE}
        isDevelopment={false}
        mediaSrc={props.src ?? ''}
        description={description}
        category={category}
        disableChangeCategory
        size={size}
        creationDate={creationDate}
        dimension={dimension}
        extension={extension}
        isUpdateSuccess={isUpdateSuccess}
        isUpdateLoading={isUpdateLoading}
        onSubmitButtonClick={onSubmitButtonClick}
      />
    </>
  );
}
